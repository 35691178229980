import React from 'react';
import { EncomGlobe } from 'encom-globe-react';
import 'encom-globe-react/dist/index.css';

const marker1 = { lat: 39.91, lon: 116.38, label: "China Satellite Communications HQ" };
const marker2 = { lat: 55.75, lon: 37.61, label: "Roskosmos HQ", connected: true };
const demoMarkers = [marker1, marker2];

const GlobeComponent = () => {
  


  const [markers, setMarkers] = React.useState([]);
  const [constellations, setConstellations] = React.useState([]);

  const demo = () => {
    //console.log("Run demo");
    // ADD MARKERS
    setTimeout(() => setMarkers(demoMarkers), 1500);

    //ADD SATELLITES
    setTimeout(() => {
      const constellation = [];
      const opts = {
        waveColor: "#FFF",
        coreColor: "#FF0000",
        shieldColor: "#fff",
        numWaves: 8
      };
      const alt = 1.3;

      for (let i = 0; i < 2; i++) {
        for (let j = 0; j < 3; j++) {
          constellation.push({
            lat: 50 * i - 30 + 15 * Math.random(),
            lon: 120 * j - 120 + 30 * i,
            altitude: alt
          });
        }
      }

      setConstellations([
        {
          opts,
          sats: constellation
        }
      ]);
    }, 6000);
  };

  return (
    <EncomGlobe
      width={500} // Set the width to 300px
      height={500}
      markers={markers}
      constellations={constellations}
      globeReadyCb={demo}
    />
  );
};

export default GlobeComponent;

import React from 'react';
import { Container, Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';


const CopyrightText = styled(Typography)({
  fontFamily: 'Montserrat, sans-serif',
  color: 'white',
  alignSelf: 'flex-end', // Align to the right
  fontSize: '15px',
  textAlign: 'right' // Align text to the right
});

const Footer = () => {
  return (
    <Container
      sx={{
		
        bottom: 0,          // Align the footer to the bottom
        left: 0,            // Align the footer to the left
        right: 0,           // Align the footer to the right
        border: 'none',
        backgroundColor: '#323232',
        maxWidth: '100%',
        boxShadow: '0px 0px 100px 7px rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'left',
      }}
      maxWidth={false}
    >
  <Grid container spacing={2}>
    <Grid item xs={3} justifyContent="flex-end">
       <div>
      <span style={{ fontSize: '16px' ,fontFamily: 'Montserrat, sans-serif',color:'white'}}>Crafted with <span role="img" aria-label="Heart">❤️</span> with ASTRES students.</span>

    </div>
    </Grid>
    
    <Grid item xs={5}>
      
    </Grid>
    <Grid item xs={4}>
      <CopyrightText variant="body2">
        &copy; {new Date().getFullYear()} Recon.space by Entropy.
      </CopyrightText>
    </Grid>
  </Grid>
</Container>
  );
};

export default Footer;

import React, { useEffect, useRef } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import MarkerClusterGroup from 'react-leaflet-cluster';

import customIconUrl from './images/rocket.svg';
import customIconUrlgroundstructure from './images/launcher.svg';

const MapComponent = ({ gpsList, organizationNames, tagsList, ids, searchQuery, onSearchQueryChange, gpsstructures, groundstructurenames, countries, idsstructures, numberoflaunches }) => {
  const mapRef = useRef(null);
  const orgMarkersClusterRef = useRef(L.markerClusterGroup()); // Cluster group for organization markers
  const groundStructureMarkersClusterRef = useRef(L.markerClusterGroup()); // Cluster group for ground structure markers

  // Initialize the map
  useEffect(() => {
    if (!mapRef.current) {
      const map = L.map('map').setView([51.5072, 0.1276], 3);

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      }).addTo(map);

      mapRef.current = map;
      
      orgMarkersClusterRef.current.clearLayers();
      groundStructureMarkersClusterRef.current.clearLayers();
      
      map.addLayer(orgMarkersClusterRef.current);
      map.addLayer(groundStructureMarkersClusterRef.current);
    }
  }, []);

  // Update organization markers
  useEffect(() => {
    orgMarkersClusterRef.current.clearLayers();

    if (gpsList && gpsList.length > 0) {
      gpsList.forEach((gps, index) => {
        if (gps && organizationNames[index].toLowerCase().includes(searchQuery.toLowerCase())) {
          const [longitude, latitude] = gps
            .replace('POINT(', '')
            .replace(')', '')
            .split(' ')
            .map((coord) => parseFloat(coord));

          const customIcon = L.icon({
            iconUrl: customIconUrl,
            iconSize: [32, 32],
            iconAnchor: [16, 32],
          });

          const marker = L.marker([latitude, longitude], {
            icon: customIcon,
          });

          const popupContent = `
            <strong>Organization:</strong> ${organizationNames[index]}<br>
            <strong>GPS Location:</strong> ${latitude}, ${longitude}<br>
            <strong>Tags:</strong> ${tagsList[index].join(', ')}<br>
            <a href="https://recon.space/Advsearch/?orgname=${ids[index]}" target="_blank">Go to the full details page</a>
          `;

          marker.bindPopup(popupContent);
          orgMarkersClusterRef.current.addLayer(marker);
        }
      });
    }
  }, [gpsList, organizationNames, tagsList, searchQuery, ids, customIconUrl]);

  // Update ground structure markers
  useEffect(() => {
    groundStructureMarkersClusterRef.current.clearLayers();
    if (gpsstructures && gpsstructures.length > 0) {
      gpsstructures.forEach((gps, index) => {
        if (gps) {
          const [longitude, latitude] = gps
            .replace('POINT(', '')
            .replace(')', '')
            .split(' ')
            .map((coord) => parseFloat(coord));

          const structureIcon = L.icon({
            iconUrl: customIconUrlgroundstructure,
            iconSize: [32, 32],
            iconAnchor: [16, 32],
          });

          const markerstructure = L.marker([latitude, longitude], {
            icon: structureIcon,
          });

          const popupContentstructure = `
            <strong>Ground Structure:</strong> ${groundstructurenames[index]}<br>
            <strong>Country:</strong> ${countries[index]}<br>
            <strong>Number of Launches:</strong> ${numberoflaunches[index]}<br>
            <strong>GPS Location:</strong> ${latitude}, ${longitude}<br>
          `;

          markerstructure.bindPopup(popupContentstructure);
          groundStructureMarkersClusterRef.current.addLayer(markerstructure);
        }
      });
    }
  }, [gpsstructures, groundstructurenames, countries, idsstructures, numberoflaunches, customIconUrlgroundstructure]);


  // Update the search query in the parent component
  useEffect(() => {
    onSearchQueryChange(searchQuery);
  }, [searchQuery, onSearchQueryChange]);

  return <div id="map" style={{ height: '600px' }}></div>;
};

export default MapComponent;

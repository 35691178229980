import React, { useState } from 'react';
import { Grid,  TextField,  Typography } from '@mui/material';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import lawIcon from './images/law.png';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import apiBaseUrl from '../Apiconfig.js';

const useStyles = {
  formContainer: {
    padding: '20px',
    textAlign: 'center',
    borderRadius: '8px', // Optional: Add border radius for rounded corners
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.5)', 
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  textField: {
    margin: '10px 0',
    width: '100%',
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green', // Custom underline color
    },
  },
  button: {
    marginTop: '20px',
  },
};

const RequestsOrganization = () => {
  const [formData, setFormData] = useState({
    country: '',
    emitter: '',
    name: '',
    description: '',
    nature: '',
    lastmodification: '',
    link: '',
    
  });

  const [recaptchaValue, setRecaptchaValue] = useState(null);

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if reCAPTCHA is ticked
    if (!recaptchaValue) {
      alert('Please complete the reCAPTCHA verification.');
      return;
    }

    try {
      // Remove any formatting characters (e.g., commas) from the funding value
      

      // Update the formData with the parsed value
      setFormData({
        ...formData,
      });

      console.log('Submitting data:', formData);
	  const endpoint = `${apiBaseUrl}/law-requests/`;
      const response = await axios.post(endpoint, formData);

      console.log('Response from server:', response);

      if (response.status === 201) {
        alert('Law request submitted successfully!');
        // Handle success, e.g., redirect or show a success message
      } else {
        alert('Failed to submit Law request. Please try again.');
        console.error('Server response:', response.data);
        // Handle error, e.g., show an error message
      }
    } catch (error) {
      alert('Error submitting Law request. Please try again.');
      console.error('Error submitting Law request:', error);
      console.log('Server response:', error.response);
    }
  };

  return (
  
   
    <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
      <Grid item xs={10} sm={8} md={6} style={useStyles.formContainer}>
        <Typography variant="h6" sx={{ color: 'white', fontFamily: 'Montserrat', fontWeight: 'bold', fontStyle: 'italic', letterSpacing: '2px' }}>
        <img src={lawIcon} alt="law icon" style={{ width: '40px', height: '40px' }} /> Submit a space good practice - law
      </Typography>
        <form style={useStyles.form} onSubmit={handleSubmit}>
        
        
   <TextField
  className={useStyles.customTextField}
  label={
    <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'white', fontFamily: 'Montserrat, sans-serif' }}>
      Law name
    </Typography>
  }
  variant="standard"
  size="small"
  name="name"
  value={formData.name}
  onChange={handleInputChange}
  required
  sx={{
    width: '100%',
   maxWidth: '600px',
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiInputLabel-root': {
      color: 'white',
    },
    '& .MuiInputLabel-asterisk': {
      display: 'none', // Hide the asterisk
    },
  }}
  inputProps={{
    style: { color: 'white', borderColor: '#9d9d9d', borderWidth: '2px' },
  }}
/>

 <TextField
  className={useStyles.customTextField}
  label={
    <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'white', fontFamily: 'Montserrat, sans-serif' }}>
      Emitter
    </Typography>
  }
  variant="standard"
  size="small"
  name="emitter"
  value={formData.emitter}
  onChange={handleInputChange}
  required
  sx={{
    width: '100%',
    maxWidth: '600px',
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiInputLabel-root': {
      color: 'white',
    },
    '& .MuiInputLabel-asterisk': {
      display: 'none', // Hide the asterisk
    },
  }}
  inputProps={{
    style: { color: 'white', borderColor: '#9d9d9d', borderWidth: '2px' },
  }}
/>

          <TextField
  className={useStyles.customTextField}
  label={
    <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'white', fontFamily: 'Montserrat, sans-serif' }}>
      Country
    </Typography>
  }
  variant="standard"
  size="small"
  name="country"
  value={formData.country}
  onChange={handleInputChange}
  required
  sx={{
    width: '100%',
   maxWidth: '600px',
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiInputLabel-root': {
      color: 'white',
    },
    '& .MuiInputLabel-asterisk': {
      display: 'none', // Hide the asterisk
    },
  }}
  inputProps={{
    style: { color: 'white', borderColor: '#9d9d9d', borderWidth: '2px' },
  }}
/>

     

          <TextField
  className={useStyles.customTextField}
  label={
    <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'white', fontFamily: 'Montserrat, sans-serif' }}>
      Description
    </Typography>
  }
  variant="standard"
  size="small"
  name="description"
  value={formData.description}
  onChange={handleInputChange}
  multiline
  rows={4}
  sx={{
    width: '100%',
    maxWidth: '600px',
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiInputLabel-root': {
      color: 'white',
    },
    '& .MuiInputLabel-asterisk': {
      display: 'none', // Hide the asterisk
    },
  }}
  inputProps={{
    style: { color: 'white', borderColor: '#9d9d9d', borderWidth: '2px' },
  }}
/>

  <TextField
  className={useStyles.customTextField}
  label={
    <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'white', fontFamily: 'Montserrat, sans-serif' }}>
      Nature
    </Typography>
  }
  variant="standard"
  size="small"
  name="nature"
  value={formData.nature}
  onChange={handleInputChange}
  sx={{
    width: '100%',
    maxWidth: '600px',
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiInputLabel-root': {
      color: 'white',
    },
    '& .MuiInputLabel-asterisk': {
      display: 'none', // Hide the asterisk
    },
  }}
  inputProps={{
    style: { color: 'white', borderColor: '#9d9d9d', borderWidth: '2px' },
  }}
/>

       
       <TextField
  className={useStyles.customTextField}
  label={
    <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'white', fontFamily: 'Montserrat, sans-serif' }}>
      Last modification
    </Typography>
  }
  variant="standard"
  size="small"
  name="lastmodification"
  type="date"
  value={formData.lastmodification}
  onChange={handleInputChange}
  required
   InputLabelProps={{
    shrink: true,
  }}
  sx={{
    width: '100%',
    maxWidth: '600px',
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiInputLabel-root': {
      color: 'white',
    },
    '& .MuiInputLabel-asterisk': {
      display: 'none', // Hide the asterisk
    },
  }}
  inputProps={{
    style: { color: 'white', borderColor: '#9d9d9d', borderWidth: '2px' },
  }}
/>


       <TextField
  className={useStyles.customTextField}
  label={
    <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'white', fontFamily: 'Montserrat, sans-serif' }}>
      Source
    </Typography>
  }
  variant="standard"
  size="small"
  name="link"
  value={formData.link}
  onChange={handleInputChange}
  required
  sx={{
    width: '100%',
    maxWidth: '600px',
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiInputLabel-root': {
      color: 'white',
    },
    '& .MuiInputLabel-asterisk': {
      display: 'none', // Hide the asterisk
    },
  }}
  inputProps={{
    style: { color: 'white', borderColor: '#9d9d9d', borderWidth: '2px' },
  }}
/>

      

      


          {/* reCAPTCHA */}
          <ReCAPTCHA
            sitekey="6Lcqq3soAAAAAIX0Y4Lbfm9x9lgQMKajthY0EqSy"
            onChange={handleRecaptchaChange}
          />

          {/* Submit Button */}
           <button
  type="submit"
  style={{
    backgroundColor: '#125212',
    fontSize: '1.5rem', // Adjust the font size to make the button bigger
    padding: '10px 20px', // Adjust the padding for more size
    borderRadius: '8px', // Add border-radius for a rounded appearance
    color: 'white', // Text color
    border: 'none', // Remove default button border
    cursor: 'pointer', // Show a pointer cursor on hover
  }}
>
  <RocketLaunchIcon fontSize="large" /> Submit
</button>
        </form>
      </Grid>
    </Grid>
  );
};

export default RequestsOrganization;


import React, { useState, useEffect } from 'react';
import axios from 'axios';
import MapComponent from './../components/MapComponent';

import MapIcon from '@mui/icons-material/Map';
import LinearProgress from '@mui/material/LinearProgress';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ListIcon from '@mui/icons-material/List';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import HelpIcon from '@mui/icons-material/Help';
import Tooltip from '@mui/material/Tooltip';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import MenuItem from '@mui/material/MenuItem';
import { Switch, FormControlLabel } from '@mui/material';

import Appbar from '../components/Appbar';
import Spacer from '../components/Spacer';
import Footer from '../components/Footer';
import apiBaseUrl from '../Apiconfig.js';

import { styled, alpha } from '@mui/material/styles';
import { red, green } from '@mui/material/colors';

import {
  Box,
  Container,
  Typography,
  Grid,
  TextField,
} from '@mui/material';

const App = () => {
  const [gpsList, setGpsList] = useState([]);
  const [organizationNames, setOrganizationNames] = useState([]);
  const [tagsList, setTagsList] = useState([]);
  const [ids, setIds] = useState([]);
  const [loadingTime, setLoadingTime] = useState(null);
  const [mapInitialized, setMapInitialized] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredOrganizations, setFilteredOrganizations] = useState([]);
  const [orgName, setOrgName] = useState('');
  const [tags, setTags] = useState('');
  
  //setting data for groundstructures
  const [idsstructures, setidsstructures] = useState([]);
  const [groundstructurenames, setgroundstructurenames] = useState('');
  const [countries, setcountries] = useState('');
  const [gpsstructures, setgpsstructures] = useState('');
  const [numberoflaunches, setnumberoflaunches] = useState('');
  
  
  
  
  const [fetchFirstAPI, setFetchFirstAPI] = useState(false);
   // Toggle switch function
   
   const handleToggle = () => {
    setFetchFirstAPI(prevState => !prevState);
  };
   
 
  
  
  
  const tagspossible = [
  {
    value: 'Agency',
    label: 'Agency',
  },
  {
    value: 'Education',
    label: 'Education',
  },
  {
    value: 'Equipment',
    label: 'Equipment',
  },
  {
    value: 'Groundstation',
    label: 'Groundstation',
  },
  {
    value: 'IT-AI-Cyber-Data',
    label: 'IT-AI-Cyber-Data',
  },
  {
    value: 'Manufacturer',
    label: 'Manufacturer',
  },
  {
    value: 'Misc',
    label: 'Misc',
  },
  {
    value: 'News',
    label: 'News',
  },
  {
    value: 'Regulatory',
    label: 'Regulatory',
  },
  {
    value: 'Research',
    label: 'Research',
  },
  {
    value: 'Satellite',
    label: 'Satellite',
  },
  {
    value: 'Service',
    label: 'Service',
  },
  {
    value: 'Tourism',
    label: 'Tourism',
  },
  {
    value: '',
    label: '',
  },
];




  const fetchData = async () => {
    try {
      const startTime = performance.now();
      setGpsList(null); // this is important otherwise when user switch off back, previous data are still shown.
      if (fetchFirstAPI) {
      let endpoint = `${apiBaseUrl}/orgnamegpspublic/`;
      const filters = [];
      console.log(filters);
      if (orgName) filters.push(`orgname=${orgName}`);
      if (tags) filters.push(`tags=${tags}`);

      if (filters.length > 0) {
        endpoint += '?' + filters.join('&');
      }
      
      const response = await axios.get(endpoint);
      const data = response.data;
      //console.log(data);

      const gpsData = data.map((item) => item.gps);
      const orgNames = data.map((item) => item.organisationname);
      const tagsData = data.map((item) => item.tags);
      const ids = data.map((item) => item.id);

      setGpsList(gpsData);
      setOrganizationNames(orgNames);
      setTagsList(tagsData);
      setIds(ids);
      setFilteredOrganizations(orgNames);}
      
      
      //loading groundstructures
      const endpointgroundstructures = `${apiBaseUrl}/groundstructures/`;
      const responsestructures = await axios.get(endpointgroundstructures);
      const datastructures = responsestructures.data;
      //console.log(datastructures);
      
      const gpsstructures = datastructures.map((item) => item.gps);
      const groundstructurenames = datastructures.map((item) => item.groundstructurename);
      const countries = datastructures.map((item) => item.country);
      const idsstructures = datastructures.map((item) => item.id);
      const numberoflaunches = datastructures.map((item) => item.totalnumberoflaunch);

      setgpsstructures(gpsstructures);
      setgroundstructurenames(groundstructurenames);
      setcountries(countries);
      setidsstructures(idsstructures);
      setnumberoflaunches(numberoflaunches);

      const endTime = performance.now();
      const timeTaken = endTime - startTime;
      setLoadingTime(timeTaken);

      setMapInitialized(true);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(interval);
          return 100;
        }
        return prevProgress + 1;
      });
    }, 170);
  }, []);

  
    useEffect(() => {
    fetchData();
  }, [fetchFirstAPI]);
  
   const handleSubmit = (e) => {
    e.preventDefault();
    
    if (!fetchFirstAPI) {
      setFetchFirstAPI(true);
    }
    
    fetchData();
  };
  


  useEffect(() => {
    const filtered = organizationNames.filter((orgName) =>
      orgName.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredOrganizations(filtered);
  }, [searchQuery, organizationNames]);
  

const CustomSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      color: green[600],
      '&:hover': {
        backgroundColor: alpha(green[600], theme.palette.action.hoverOpacity),
      },
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.5,
    },
    '&:not(.Mui-checked)': {
      color: red[600],
      '&:hover': {
        backgroundColor: alpha(red[600], theme.palette.action.hoverOpacity),
      },
    },
  },
  '& .MuiSwitch-track': {
    backgroundColor: red[600],
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: green[600],
  },
}));

  return (
    <div>
    <Appbar/>
    <Spacer height="20px" />
    
  
    <Container  
  sx={{
    borderRadius: '6px',
    border: 'none',
    maxWidth: '100%',
    boxShadow: '0px 0px 100px 7px rgba(0, 0, 0, 0.5)',
    display: 'flex',            // Apply Flexbox to the container
    padding: '20px' //space between last component and the container 
    
  }}
  maxWidth={false}
>


<Grid container spacing={2}>
          <Grid item xs={4}>
           <Typography variant="h6" sx={{ color: 'white', fontFamily: 'Montserrat', fontWeight: 'bold', fontStyle: 'italic', letterSpacing: '2px' }}>
        <MapIcon sx={{ fontSize: '1.2em', marginRight: '8px', verticalAlign: 'middle' }} /> Map search | Demo
      </Typography>
           </Grid>
           <Grid item xs={4}>
          <Box
      display="flex"
      alignItems="center"
      
    >
    <Typography variant="body1" sx={{color: 'white', textAlign: 'center',fontWeight: 'bold',fontFamily: 'Montserrat, sans-serif',}}>
        This, is one of the Recon[.]Space API demo. You can test the endpoint orgnamegps/.
      </Typography>
      
      <Tooltip title= <div> Recon[.]Space provides specific features to help you analyze the data. The endpoint orgnamegps/ is built in a way to easy retrieve entity location data and more.
          
          
        </div> arrow> 
      <IconButton color="primary">
        <HelpIcon />
      </IconButton>
    </Tooltip>
     </Box>
     
      
      
          </Grid>
           <Grid item xs={3}>
           
          
           </Grid>
           <Grid item xs={1}>
            <p> {loadingTime ? (
          <Typography variant="body1">
            <AccessTimeIcon style={{ color: '#4caf50', marginRight: '8px', fontSize: '1rem' }} />{' '}
            <strong style={{ color: '#4caf50' }}>{loadingTime.toFixed(2)} ms</strong>
          </Typography>
        ) : (
          null
        )}</p>
           </Grid>
            <Grid item xs={4}>
            
            </Grid>
            <Grid item xs={4}>
             <Alert severity="success">
    Demo of the "orgnamegps/" API endpoint. The results are limited to <strong>a sample of rows</strong> (~66% of the endpoint total rows).
</Alert>
            </Grid>
            <Grid item xs={3}>
            
     
      
            </Grid>
            
            <Grid item xs={4}>
            
             </Grid>
              <Grid item xs={4}>
              
      
       
      
     
      
             </Grid>
              <Grid item xs={3}>
             </Grid>
            
            
          </Grid>
      
     
      </Container>
      
       <Spacer height="40px" />
      
    
      {mapInitialized && ( <Container
        sx={{
          borderRadius: '6px',
          border: 'none',
          maxWidth: '100%',
          boxShadow: '0px 0px 100px 7px rgba(0, 0, 0, 0.5)',
          display: 'flex',
          padding: '20px',
        }}
        maxWidth={false}
      >
        <Grid container spacing={2}>
          <Grid item xs={3}>
           {mapInitialized && (
     <Box
  sx={{
    
    padding: '20px',
    maxHeight: '580px',
    overflowY: 'auto',
    backgroundColor: '#1d1d21',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 1)', // Add a shadow
    borderRadius: '4px', // Add rounded corners if desired
    marginBottom: '40px'
  }}
>

 <div style={{ display: 'flex', alignItems: 'center' ,marginBottom: '16px'}}>
    <ListIcon fontSize="medium" sx={{ color: 'white' }} />
  <Typography
    variant="h6"
    sx={{
      color: 'white',
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 'bold',
      marginLeft: '8px', // Add spacing between icon and text
      
    }}
  >
    <div>
      <FormControlLabel
        control={
          <CustomSwitch 
            checked={fetchFirstAPI} 
            onChange={handleToggle} 
          />
        }
        label={`Organizations ${fetchFirstAPI ? 'ON' : 'OFF'}`}
      />
    </div>
  </Typography>
  
  
</div>

<form onSubmit={handleSubmit}>
              
        
        
        
       <TextField
     label={
    <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'white' ,fontFamily: 'Montserrat, sans-serif', }}>
      Search by organization name
    </Typography>
  }
    variant="standard"
    value={orgName}
    onChange={(e) => setOrgName(e.target.value)}
    size="small"
   sx={{
        marginRight: '20px',
        width: '100%',
        '& .MuiInput-underline:after': {
          borderBottomColor: 'green', // Custom underline color
        },
      }}
    inputProps={{
      style: { color: 'white' ,borderColor: "white",borderWidth: '2px'}, // Set the color and font weight of the input text to white 
    }}
   
  />
  
  
    
          <TextField
      id="outlined-select-tags"
      select
      label={
        <Typography variant="caption" sx={{ fontWeight: 'bold', color: 'white', fontFamily: 'Montserrat, sans-serif' }}>
          Search by tags
        </Typography>
      }
      size="small"
      variant="standard"
      value={tags}
      sx={{
        width: '100%',
        '& .MuiInput-underline:after': {
          borderBottomColor: 'green', // Custom underline color
        },
        '& .MuiSelect-select': {
          color: 'white',
          textAlign: 'left', // Set the color of the selected text
        },
      }}
      onChange={(e) => setTags(e.target.value)}
      helperText={
        <Typography variant="caption" sx={{ fontWeight: 'bold', color: 'white', fontFamily: 'Montserrat, sans-serif' }}>
          Display all companies location using orgname and tags filters.
        </Typography>
      }
    >
      {tagspossible.map((option) => (
        <MenuItem key={option.label} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>

  
       <button type="submit" style={{ backgroundColor: '#125212' }}> <RocketLaunchIcon fontSize="medium" /></button>
       
       
      </form>
     
      
      {/* 
<TextField
  label={
    <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'white', fontFamily: 'Montserrat, sans-serif', textAlign: 'center' }}>
      Search orgnames
    </Typography>
  }
  variant="outlined"
  fullWidth
  value={searchQuery}
  onChange={(e) => setSearchQuery(e.target.value)}
  InputProps={{
    sx: {
      color: 'white', // Change input text color to white
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'white', // Change outline color to white by default
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'white', // Change outline color to white on hover
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#4caf50', // Change outline color to green when focused (clicked)
      },
      marginBottom: '16px', // Add margin bottom to the input field
    },
  }}
  InputLabelProps={{
    sx: {
      color: 'white !important', // Change label text color to white
      textAlign: 'center', // Center the label text within the TextField
    },
  }}
  
/>


   <Box
  sx={{
    
    padding: '20px',
    maxHeight: '350px',
    overflowY: 'auto',
    backgroundColor: '#1d1d21',
    borderRadius: '4px', // Add rounded corners if desired
    marginBottom: '16px'
  }}
>
     <ul
  style={{
    listStyleType: 'none', // Remove bullet points
    padding: 0, // Remove default padding
  }}
>


      
  {filteredOrganizations.map((orgName, index) => (
  <li
    key={index}
    style={{
      marginBottom: '8px', // Add spacing between list items (adjust as needed)
    }}
  >
    <a
      href={`https://recon.space/Advsearch/`} //need to fix id in the list
      //href={`http://127.0.0.1:3000/Advsearch/?search=${ids[index]}`}  // Insert the org's id into the URL
      style={{
        color: 'white', // Set link text color to white
        textDecoration: 'none', // Remove underlines from the link
        fontFamily: 'Montserrat, sans-serif', // Apply the same font as before
        fontWeight: 'bold', // Apply the same font weight as before
      }}
    >
      {orgName}
    </a>
  </li>
))}


</ul>
 </Box>*/}
  
    </Box>
             )}
            
          </Grid>
          <Grid item xs={9}>
            {mapInitialized && (
              <>
                <MapComponent
                  gpsList={gpsList}
                  organizationNames={organizationNames}
                  tagsList={tagsList}
                  ids={ids}
                  searchQuery={searchQuery}
                  gpsstructures={gpsstructures}
                  groundstructurenames={groundstructurenames}
                  countries={countries}
                  idsstructures={idsstructures}
                  numberoflaunches={numberoflaunches}
                  onSearchQueryChange={setSearchQuery}
                />
               
              </>
            )}
          </Grid>
        </Grid>
         
      </Container>)}
<LinearProgress
                  variant="determinate"
                  value={loadingTime ? 100 : progress}
                  sx={{
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: '#4caf50',
                    },
                    '&.MuiLinearProgress-root': {
                      backgroundColor: 'black',
                    },
                  }}
                />
      <Spacer height="40px" />
      <Footer />
      <Spacer height="3px" />
    </div>
  );
};

export default App;

import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Footer from '../components/Footer';
import Spacer from '../components/Spacer';
import axios from 'axios';
import HelpIcon from '@mui/icons-material/Help';
import Tooltip from '@mui/material/Tooltip';
import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';

// Import your icons
import laserSwordIcon from './images/laser-sword.png';
import meteorShowerIcon from './images/meteor-shower.png';
import jammerIcon from './images/jam.png';
import hackerIcon from './images/hacker.png';
import ASATKIcon from './images/missile.png';
import ABMIcon from './images/missiles.png';

import Appbar from '../components/Appbar';

import LaunchIcon from '@mui/icons-material/Launch';

import './css/starwars.css';
import apiBaseUrl from '../Apiconfig.js';


const StarWars = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filterType, setFilterType] = useState('');

  useEffect(() => {
    // Fetch data using Axios
    const endpoint = `${apiBaseUrl}/weaponspublic/`;
    axios.get(endpoint)
      .then((response) => {
        setData(response.data.map(item => ({ ...item, showFullDescription: false })));
        setFilteredData(response.data.map(item => ({ ...item, showFullDescription: false })));
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  useEffect(() => {
    if (filterType) {
      const filteredResults = data.filter((item) => item.vectortype === filterType);
      setFilteredData(filteredResults);
    } else {
      setFilteredData(data);
    }
  }, [data, filterType]);

  const toggleDescription = (index) => {
  const updatedData = [...filteredData];  // Use filteredData instead of data
  updatedData[index].showFullDescription = !updatedData[index].showFullDescription;
  setFilteredData(updatedData);
};

  const getIconForType = (type) => {
    switch (type) {
      case 'ABM':
        return ABMIcon;
      case 'ASAT kinetic':
        return ASATKIcon;
      case 'Space-Based missiles':
        return meteorShowerIcon;
      case 'ASAT non-kinetic':
        return laserSwordIcon;
      case 'Cyber':
        return hackerIcon;
      case 'Electronic Warfare systems':
        return jammerIcon;
      // Add more cases as needed for other vectortypes
      default:
        return null; // Default icon if the type is not recognized
    }
  };

const [isBlinking, setIsBlinking] = useState(false);

  const handleButtonClick = (type) => {
    setFilterType(type);

    // Enable blinking animation only for the "Cyber" button
    if (type === 'Cyber') {
      setIsBlinking(true);
      setTimeout(() => setIsBlinking(false), 400); // Disable animation after 2 seconds
    }
  };

  return (
  <div style={{ position: 'relative', minHeight: '100vh' }}>
      {/* Linear gradient background */}
      <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: -1, background: 'linear-gradient(125deg, #383838, #000000)' }}></div>
      <Appbar/>
      
      
      <Container
        sx={{
          
          borderRadius: '6px',
          border: 'none',
          maxWidth: '100%',
          boxShadow: '0px 0px 100px 7px rgba(0, 0, 0, 0.5)',
        }}
        maxWidth={false}
      >
       <Grid
  container
  spacing={0}
  direction="column"
  alignItems="center"
  
>

  <Grid item xs={3}>
  <Box
      display="flex"
      alignItems="center"
      
    >
    <Typography variant="body1" sx={{color: 'white', textAlign: 'center',fontWeight: 'bold',fontFamily: 'Montserrat, sans-serif',}}>
        This, is one of the Recon[.]Space API demo. You can test the endpoint weapons/.
      </Typography>
      
      <Tooltip title= <div> Recon[.]Space provides specific features to help you analyze the data. The endpoint weapons/ is built in a way to easy retrieve space weapons technical details.
          
          
        </div> arrow> 
      <IconButton color="primary">
        <HelpIcon />
      </IconButton>
    </Tooltip>
     </Box>
      <Alert severity="success">
    Demo of the "weapons/" API endpoint. All results of the database are presented here; however not all fields displayed. (4 fields out of 9.)<br/>Each icon represents a class of weapon.
</Alert>
<Spacer height="20px" />
  </Grid>
</Grid>
      
       </Container>
      
      
    <Container>
      <Spacer height="20px" />
      

<div>
      <div
        className="button-container"
        style={{
          display: 'flex',
          marginTop: '10px', // Adjust the top margin as needed
        }}
      >
        <button
          className={`type-button ${filterType === '' ? 'active' : ''}`}
          onClick={() => handleButtonClick('')}
          style={{
            marginRight: '10px', // Adjust the right margin as needed
            padding: '8px 12px',
            cursor: 'pointer',
            backgroundColor: filterType === '' ? '#0b2f0c' : '#135015',
            color: '#fff',
            border: 'none',
            borderRadius: '4px',
          }}
        >
          All
        </button>

        {Array.from(new Set(data.map((item) => item.vectortype))).map((type) => (
          <button
            key={type}
            className={`type-button ${filterType === type ? 'active' : ''} ${
              type === 'Cyber' && isBlinking ? 'blink-animation' : ''
            }`}
            onClick={() => handleButtonClick(type)}
            style={{
              marginRight: '10px', // Adjust the right margin as needed
              padding: '8px 12px',
              cursor: 'pointer',
              backgroundColor: filterType === type ? '#0b2f0c' : '#1d7c1f',
              color: '#fff',
              border: 'none',
              borderRadius: '4px',
            }}
          >
            {type}
          </button>
        ))}
      </div>
    </div>

      <Spacer height="20px" />

      <Grid container spacing={2}>
        {filteredData.map((item, index) => (
          <Grid item key={item.id} xs={12} md={6} lg={4}>
            <Card style={{ background: '#3a3a3a' ,height: '100%', display: 'flex', flexDirection: 'column', position: 'relative' }}>
              <CardContent style={{ flex: 1 }}>
                {/* Display the dynamically chosen icon in the upper right corner */}
                {getIconForType(item.vectortype) && (
                  <img
                    src={getIconForType(item.vectortype)}
                    alt={`${item.vectortype} Icon`}
                    style={{
                      position: 'absolute',
                      top: '10px',
                      right: '10px',
                      width: '30px',
                      height: '30px',
                      borderRadius: '50%',
                    }}
                  />
                )}

                <Typography variant="h6" style={{ color: '#fff', fontFamily: 'Montserrat', fontWeight: 'bold' }}>{item.name}</Typography>

                 <Typography
                    color="textSecondary"
                    gutterBottom
                    style={{ cursor: 'pointer',fontFamily: 'Montserrat', color: '#eaeaea' }}
                    onClick={() => toggleDescription(index)}>
                    {item.vectortype}
                  </Typography>
                <Typography
				  variant="body2"
				  component="p"
				  style={{ cursor: 'pointer', color: '#a7a7a7',fontFamily: 'Montserrat', fontWeight: 'normal' }} // Adjust the color value and fontWeight as needed
				  onClick={() => toggleDescription(index)}
				>
				  {item.showFullDescription
					? item.description
					: `${item.description.substring(0, 100)}...`}
				</Typography>
				
  <a
        href={item.source}
        target="_blank"
        rel="noopener noreferrer"
        style={{
          marginTop: '10px', // Adjust the marginTop value as needed
          display: 'block', // Ensure the <a> element behaves like a block-level element
          color: '#1d7c1f', // Set the text color to black
          textDecoration: 'none', // Remove underline (if needed)
          fill: 'green', // Set the color of the LaunchIcon to black
        }}
      >
        <LaunchIcon />
      </a>

              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      
    </Container>
    <Spacer height="40px" />
      <Footer />
      <Spacer height="3px" />
    </div>
  );
};

export default StarWars;

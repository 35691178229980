import React from 'react';
import { Container, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import Divider from '@mui/material/Divider';



import QuizIcon from '@mui/icons-material/Quiz';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQContainer = () => {
  const faqs = [
    {
      question: '1 - What is Recon[.]Space ?',
      answer: 'Recon[.]Space is a data platform. It provides reliable data through an API that can be useful for market analysis, space entities intelligence, laws maker, ethical hacking, IT researches and more.',
    },
    {
      question: '2 - What kind of data the API provides?',
      answer: 'Organisation name, description, country code, gps exact location, employees number, data of creation, category/tag, creator, funding type, financial data, domain, Ip addresses, technologies and satellites used by entity, weapons technical details, SIGINT centers details, Laws, source of data and much more.',
    },
    {
      question: '3 - How do we get our data?',
      answer: 'Recon[.]Space works with partners, frequent contributors and uses scan engines to gather the data. The data are accumulated in a data lake, then filtered and sorted to provide the best quality. Amongst the filtering and data quality processes, some manual analysis is made; for example for the top 50 space companies, Recon[.]Space manually assess the quality of the data which provide a full confidence.',
    },
    {
      question: '4 - How can I contact you using encryption ?',
      answer: (
    <div>
      <p>
        Recon.space public {' '}
        <a href="https://recon.space/pubkey" download>
           key.
        </a>
        </p>
    </div>
  ),
    },
    {
      question: '5 - What is the relation with ASTRES',
      answer: 'ASTRES is a space association for students. Recon[.]Space wants to promote space related associations and students.',
    },
    {
      question: '6 - What about the cookies and my data?',
      answer: 'No optional data is collected by Recon.space, that\'s why there is no choice about your cookie. To make it clear, we don\'t collect any of your data for other purpose than running the app.',
    },
    // Add more FAQ items as needed
  ];

  return (
    <Container sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        justifyContent: 'left',
        borderRadius: '6px',
        border: 'none',
        maxWidth: '100%',
      }}
      maxWidth={false}
    >
    
     
          {/* Repeat this grid item for each MediaCard */}
         <Divider
  sx={{
    "&::before, &::after": {
      borderColor: "white", // Set the border color to white
    },
  }}
>
  <Typography variant="h5" align="center" gutterBottom style={{ color: 'white', fontFamily: 'Montserrat, sans-serif', textAlign: 'left', display: 'flex', alignItems: 'center' }}>
  <QuizIcon style={{ marginRight: '10px' }} /> {/* Quiz icon */}
  FAQ - Frequently Asked Questions
</Typography>
</Divider>
            
      {faqs.map((faq, index) => (
        <Accordion key={index} sx={{ backgroundColor: '#09080A49', color: 'white' }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}>
  <Typography variant="h6">{faq.question}</Typography>
</AccordionSummary>

          <AccordionDetails>
            <Typography>{faq.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
          
         
      
    </Container>
  );
};

export default FAQContainer;

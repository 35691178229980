import React, { useState, useEffect,useContext } from 'react';
import axios from 'axios';

import { Box, Container, Typography,SvgIcon,Button, Dialog, DialogTitle, DialogContent, DialogActions, Divider} from '@mui/material';
import Grid from '@mui/material/Grid';
import Spacer from '../components/Spacer';
import Footer from '../components/Footer';



import BusinessIcon from '@mui/icons-material/Business';
import { TagOutlined, Flag } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import ComputerIcon from '@mui/icons-material/Computer';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import SatelliteAltIcon from '@mui/icons-material/SatelliteAlt';
import GavelIcon from '@mui/icons-material/Gavel';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';

import Flowimage from './images/flow.png';
import './css/NeonText.css'; // Import the CSS file

import lightsaberIcon from './images/light-saber.png';
import Restrictedimage from './images/restricted.png';
import Appbar from '../components/Appbar';
import apiBaseUrl from '../Apiconfig.js';


import AuthContext from '../utils/AuthContext'
import SearchFormId from '../components/SearchFormId';
import Alert from '@mui/material/Alert';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import { jwtDecode } from "jwt-decode";


const App = () => {
  const [open, setOpen] = useState(false);
  const [openIP, setOpenIP] = useState(null);
  const [openSubdomain, setOpenSubdomain] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [data, setData] = useState({});
  const [error, setError] = useState(null);
  const [datalaws, setDatalaws] = useState({});
  const [datafinancial, setDatafinancial] = useState({});
  const [openSatellite, setOpenSatellite] = useState(null);
  
  let {authTokens} = useContext(AuthContext);
  const decodedToken = jwtDecode(String(authTokens.access));
  const userType = decodedToken.usertype;
  

const handleOpensat = (satellite) => {
  setOpenSatellite(satellite);
};

const handleClosesat = () => {
  setOpenSatellite(null);
}



const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  
const handleOpenIP = (ip) => {
  setOpenIP(ip);
};

const handleCloseIP = () => {
  setOpenIP(null);
};
  
  const handleOpenSubdomain = (subdomain) => {
    setOpenSubdomain(subdomain);
  };

  const handleCloseSubdomain = () => {
    setOpenSubdomain(null);
  };


  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };
  







  useEffect(() => {
    const url = new URL(window.location.href);
    const searchParam = url.searchParams.get('orgname');

    if (searchParam) {
      setSearchQuery(searchParam);
      fetchData(); // Fetch data immediately if searchParam is present
    }
  }, []);
  
  


const fetchData = () => {
	let endpoint;
	if (userType === 'anon') {
  endpoint = `${apiBaseUrl}/orgnameanon/${searchQuery}/`;
} else {
  endpoint = `${apiBaseUrl}/orgname/${searchQuery}/`;
  console.log(decodedToken);
}
  axios.get(endpoint, {
    headers: {
      Authorization: `JWT ` + String(authTokens.access)
    }
  })
  .then(response => {
    const newData = { ...response.data }; // Copy of response data

    if (response.data.domains && response.data.domains.length > 0 && userType !== 'anon') {
	  
      const domainId = response.data.domains[0].id;
      fetchCustomData(domainId, newData); // Pass newData as argument
      fetchTagLawsData();
      fetchFinancial(newData.financial);
      
    } else {
      setData(newData); // Set data with newData
    }
  })
  .catch(err => {
    setData({});
  });
};
  


  
const fetchCustomData = (domainId, newData) => {
  const endpoint = `${apiBaseUrl}/domain/${domainId}/`;
  axios.get(endpoint, {
    headers: {
      Authorization: `JWT ` + String(authTokens.access)
    }
  })
  .then(response => {
    const updatedData = { ...newData, ...response.data }; // Merge newData with domain data
    //console.log(updatedData)
    setError(null);
	
    // Check if subdomains exist
    if (response.data.subdomain && response.data.subdomain.length > 0 ) {
		
      // Gather subdomain IDs
      const subdomainIds = response.data.subdomain.map(subdomain => subdomain.id);
      fetchCustomDataForSubdomains(subdomainIds, updatedData);
    }

    if (response.data.ip && response.data.ip.length > 0 ) {
      const ipIds = response.data.ip.map(ip => ip.id);
      fetchCustomDataForIPs(ipIds, updatedData, response.data.ip); // Pass updatedData and ip data as arguments
    }
     else {
      setData(newData); // Set data with newData
    }

    // Log the contents of response.data.subdomain
  })
  .catch(err => {
    setData({});
    setError('Error fetching data for domains.');
  });
};


const fetchCustomDataForSubdomains = (subdomainIds, updatedData) => {
  const customRequests = subdomainIds.map(subdomainId => {
	  const endpoint = `${apiBaseUrl}/subdomain/${subdomainId}/`;
    return axios.get(endpoint, {
      headers: {
        Authorization: `JWT ` + String(authTokens.access)
      }
    });
  });

  axios.all(customRequests)
    .then(responses => {
      const customData = responses.map(response => response.data);
      // Merge customData with updatedData under 'subdomain' key
      updatedData.subdomain = { subdomains: customData };
      setData(updatedData);
    })
    .catch(err => {
      setError('Error fetching custom data for subdomains.');
    });
};



const fetchCustomDataForIPs = (ipIds, updatedData, ipData) => {
  const customRequests = ipIds.map(ipId => {
	  const endpoint = `${apiBaseUrl}/ip/${ipId}/`;
    return axios.get(endpoint, {
      headers: {
        Authorization: `JWT ` + String(authTokens.access)
      }
    });
  });

  axios.all(customRequests)
    .then(responses => {
      const customData = responses.map(response => response.data);
      const mergedData = { ...updatedData, Domaininfos: ipData, IPs: customData }; // Merge customData with updatedData
      setData(mergedData);
    })
    .catch(err => {
      setError('Error fetching custom data for IPs.');
    });
};

 const fetchTagLawsData = () => {
	  const endpoint = `${apiBaseUrl}/taglaws/`;
    return axios.get(endpoint, {
      headers: {
        Authorization: `JWT ` + String(authTokens.access)
      }
    })
    .then((response) => {
      const laws = response.data; // Extract the laws from the response
      setDatalaws(laws); // Update the state variable with laws
      return laws; // Return the laws directly
    })
    .catch((error) => {
      // Handle any errors here
      console.error(error);
      throw error; // Re-throw the error if necessary
    });
  };
  
const fetchFinancial = async (financialArray) => {
  try {
    const financialData = {}; // Object to store financial data
    
    // Iterate over each ID in the financialArray
    for (const id of financialArray) {
      const endpoint = `${apiBaseUrl}/financial/${id}/`; // Construct the API endpoint for the current ID
      const response = await axios.get(endpoint, {
        headers: {
          Authorization: `JWT ${authTokens.access}`
        }
      }); // Make the API call with the Authorization header
      
      // Store the financial data for the current ID
      financialData[id] = response.data;
    }
	//console.log(financialData);
	setDatafinancial(financialData);
    return financialData; // Return the accumulated financial data
  } catch (error) {
    console.error('Error fetching financial data:', error);
    return null; // Return null in case of error
  }
};

const renderFinancial = () => {
    if (!datafinancial || Object.keys(datafinancial).length === 0) {
        return <p>No financial data available</p>;
    }

    // Extract all unique field names from the data
    const allFields = Object.values(datafinancial).reduce((fields, item) => {
        Object.keys(item).forEach(field => {
            if (!fields.includes(field)) {
                fields.push(field);
            }
        });
        return fields;
    }, []);

    // Filter out fields where all values are null
    const validFields = allFields.filter(fieldName => {
        return Object.values(datafinancial).some(item => item[fieldName] !== null);
    });

    // Define column name mappings
    const columnMappings = {
        'numberoffundingrounds': 'number of funding rounds',
'fundingtotalamount': 'funding total amount',
'lastfundingdate': 'last funding date',
'lastfundingamount': 'last funding amount',
'lastfundingtype': 'last funding type',
'fundingstatus': 'funding status',
'stockexchange': 'stock exchange',
'acquisitiontransactionname': 'acquisition transaction name',
'acquisitionprice': 'acquisition price',
'acquisitionaquiredby': 'acquisition acquired by',
'acquisitionannouceddate': 'acquisition annouced date',
'acquisitiontype': 'acquisition type',
'acquisitionterms': 'acquisition terms',
'acquisitionstatus': 'acquisition status',
'numberofacquisitions': 'number of acquisitions',
'ipodate': 'ipo date',
'ipovaluation': 'ipo valuation',
'moneyraisedatipo': 'money raised at ipo',
'ipostatus': 'ipo status',
'ipodelisteddate': 'ipo delisted date',
'numberofexitstipo': 'number of exits ipo',
'lastleadershiphiringdate': 'last leadership hiring date',
'numberofinvestments': 'number of investments',
'topinvestors': 'top investors',
'numberofportfolioorganizations': 'number of portfolio organizations',
'investortype': 'investor type',
'investmentstage': 'investment stage',
'numberofinvestors': 'number of investors',
'numberofleadinvestments': 'number of lead investments',
'numberofdiversityinvestments': 'number of diversity investments',
'numberofexists': 'number of exists',
'estimatedrevenuerange': 'estimated revenue range',
        
    };

    // Rename columns
    const renamedFields = validFields.map(fieldName => columnMappings[fieldName] || fieldName);

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow style={{ backgroundColor: '#0b350b', color: 'white' }}>
                        {renamedFields.map((fieldName, index) => (
                            <TableCell key={index} style={{ color: 'white', fontWeight: 'bold', fontFamily: 'Montserrat, sans-serif' }}>{fieldName}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.values(datafinancial).map((item, rowIndex) => (
                        <TableRow key={rowIndex} style={{ backgroundColor: '#1d1d21' }}>
                            {validFields.map((fieldName, colIndex) => (
                                <TableCell key={colIndex} style={{ color: 'white', fontFamily: 'Montserrat, sans-serif' }}>{item[fieldName] !== null ? item[fieldName] : ''}</TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};










const renderLaws = () => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow style={{ backgroundColor: '#0b350b', color: 'white' }}>
            <TableCell style={{ color: 'white', fontWeight: 'bold', fontFamily: 'Montserrat, sans-serif' }}>Country</TableCell>
            <TableCell align="left" style={{ color: 'white', fontWeight: 'bold', fontFamily: 'Montserrat, sans-serif' }}>Emitter</TableCell>
            <TableCell style={{ color: 'white', fontWeight: 'bold', fontFamily: 'Montserrat, sans-serif' }}>Name</TableCell>
            <TableCell style={{ color: 'white', fontWeight: 'bold', fontFamily: 'Montserrat, sans-serif' }}>Description</TableCell>
            <TableCell style={{ color: 'white', fontWeight: 'bold', fontFamily: 'Montserrat, sans-serif' }}>Nature</TableCell>
            <TableCell style={{ color: 'white', fontWeight: 'bold', fontFamily: 'Montserrat, sans-serif' }}>Last Modification</TableCell>
            <TableCell style={{ color: 'white', fontWeight: 'bold', fontFamily: 'Montserrat, sans-serif' }}>Reference</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
         {Array.isArray(datalaws) && data.tags.map((tag) => {
  const tagData = datalaws.find((entry) => entry.tag === tag);

  if (tagData && Array.isArray(tagData.laws) && tagData.laws.length > 0) {
    return tagData.laws.map((law) => (
                <TableRow key={law.id} style={{ backgroundColor: '#1d1d21' }}>
                  <TableCell style={{ color: 'white', fontFamily: 'Montserrat, sans-serif' }}>{law.country}</TableCell>
                  <TableCell style={{ color: 'white', fontFamily: 'Montserrat, sans-serif' }}>{law.emitter}</TableCell>
                  <TableCell style={{ color: 'white', fontFamily: 'Montserrat, sans-serif' }}>{law.name}</TableCell>
                  <TableCell style={{ color: 'white', fontFamily: 'Montserrat, sans-serif' }}>{law.description}</TableCell>
                  <TableCell style={{ color: 'white', fontFamily: 'Montserrat, sans-serif' }}>{law.nature}</TableCell>
                  <TableCell style={{ color: 'white', fontFamily: 'Montserrat, sans-serif' }}>{law.lastmodification}</TableCell>
                  <TableCell style={{ color: 'white', fontFamily: 'Montserrat, sans-serif' }}>
  {law.link && (
    <Link
      href={law.link}
      target="_blank"
      rel="noopener noreferrer"
      style={{ textDecoration: 'none', fontWeight: 'bold', color: 'white' }}
    >
      Reference
    </Link>
  )}
</TableCell>
                </TableRow>
              ));
            }
            return null;
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};


const renderSubdomains = () => {
  if (data.subdomain && data.subdomain.subdomains && data.subdomain.subdomains.length > 0) {
    return data.subdomain.subdomains.map((subdomain, index) => (
      <div key={index}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleOpenSubdomain(subdomain)}
          sx={{
            display: 'inline-block',
            backgroundColor: 'transparent',
            padding: '5px',
            borderRadius: '5px',
            marginRight: '5px', // Add some spacing between tags
            '&:hover': {
              backgroundColor: '#0b350b', // Change to green color on hover
            },
          }}
        >
          {subdomain.subdomain}
        </Button>
        <Dialog open={openSubdomain === subdomain} onClose={handleCloseSubdomain}>
          <DialogTitle>{subdomain.subdomain}</DialogTitle>
          <DialogContent>
            <Typography>
              <div>
                <pre>
                  id:{subdomain.id}<br />
                  url:{subdomain.url}<br />
                  port:{subdomain.port}<br />
                  webserver:{subdomain.webserver}<br />
                  statuscode:{subdomain.statuscode}<br />
                  technologies:{subdomain.technologies}<br />
                  ipsubdom:{subdomain.ipsubdom}<br />
               
                </pre>
              </div>
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseSubdomain} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    ));
  }
  return null; // Return null if there are no subdomains
};

const renderTechnologies = () => {
  if (
    data.subdomain &&
    data.subdomain.subdomains &&
    data.subdomain.subdomains.length > 0
  ) {
    const uniqueTechnologies = new Set();

    // Iterate through subdomains and their technologies
    data.subdomain.subdomains.forEach((subdomain) => {
      if (subdomain.technologies) {
        subdomain.technologies.split(',').forEach((technology) => {
          const trimmedTechnology = technology.trim();
          if (trimmedTechnology) {
            uniqueTechnologies.add(trimmedTechnology);
          }
        });
      }
    });

    // Convert the Set back to an array for rendering
    const uniqueTechnologyArray = Array.from(uniqueTechnologies);

     return uniqueTechnologyArray.map((technology, techIndex) => (
      <div
        key={techIndex}
        style={{
          marginRight: '10px', // Add spacing between boxes if needed
          backgroundColor: '#ccc', // Change the background color if desired
          padding: '5px 10px',
          borderRadius: '5px',
          fontFamily: 'Montserrat, sans-serif', // Set the font to Montserrat
          marginBottom: '10px', // Add spacing between boxes
        }}
      >
        {technology}
      </div>
    ));
  }
  return null; // Return null if there are no subdomains
};



 const renderTags = () => {
    if (data.tags && data.tags.length > 0) {
	 	
      return data.tags.map((tag, index) => (
        <Box
          key={index}
          sx={{
            display: 'inline-block',
            backgroundColor: 'transparent',
            borderRadius: '5px',
            marginRight: '5px', // Add some spacing between tags
          }}
        >
           <Typography variant="h6" sx={{ fontFamily: 'Montserrat', color: 'white', display: 'flex', alignItems: 'center' }}>
      
      <Box
        component="span"
        sx={{
          fontFamily: 'Montserrat',
          color: 'white',
          backgroundColor: 'rgba(255, 255, 255, 0.1)', // Diffuse (blurry) background color
          backdropFilter: 'blur(10px)', // Apply blur effect
          padding: '2px 4px', // Add padding for spacing
          borderRadius: 1, // Rounded corners
          marginBottom: 1,
          // Add space between "|" and the box
        }}
      >
        {tag}
      </Box>
    </Typography>
        </Box>
      ));
    }
    return '';
  };
  



  const renderIPs = () => {
  if (data.IPs && data.IPs.length > 0) {
    return data.IPs.map((ip, index) => (
      <div key={index}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleOpenIP(ip)}
          sx={{
            display: 'inline-block',
            backgroundColor: 'transparent',
            padding: '5px',
            borderRadius: '5px',
            marginRight: '5px',
            '&:hover': {
              backgroundColor: '#0b350b',
            },
          }}
        >
          {ip.ipaddress}
        </Button>
        <Dialog open={openIP === ip} onClose={handleCloseIP}>
          <DialogTitle>{ip.ipaddress}</DialogTitle>
          <DialogContent>
            <Typography>
              <div>
                 <pre>
          id: {ip.id} <br />
          ipaddress: {ip.ipaddress} <br />
          ipcontinent: {ip.ipcontinent} <br />
          iptimezone_field: {ip.iptimezone_field} <br />
          iplongitude: {ip.iplongitude} <br />
          iplatitude: {ip.iplatitude} <br />
          ipgeopoint: {ip.ipgeopoint} <br />
          ippostal_code: {ip.ippostal_code} <br />
          ipcity: {ip.ipcity} <br />
          ipregion_hierarchy: {ip.ipregion_hierarchy} <br />
          ipregion: {ip.ipregion} <br />
          ipregion_code: {ip.ipregion_code} <br />
          ipcountry_code: {ip.ipcountry_code} <br />
          ipcountry: {ip.ipcountry} <br />
          </pre>
              </div>
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseIP} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    ));
  }
  return null;
};






const renderSatellites = () => {
  if (data.satellites && data.satellites.length > 0) {
    return data.satellites.map((satellite, index) => (
      <div key={index}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleOpensat(satellite)}
          sx={{
            display: 'inline-block',
            backgroundColor: 'transparent',
            padding: '5px',
            borderRadius: '5px',
            marginRight: '5px',
            '&:hover': {
              backgroundColor: '#0b350b',
            },
          }}
        >
          {satellite.officialname}
        </Button>
        <Dialog open={openSatellite === satellite} onClose={handleClosesat}>
          <DialogTitle>{satellite.officialname}</DialogTitle>
          <DialogContent>
            <Typography>
              <div>
                <pre>
            official name: {satellite.officialname} <br />
            country code: {satellite.countrycode} <br />
            country operator: {satellite.countryoperator} <br />
            operator: {satellite.operator} <br />
            users: {satellite.users} <br />
            purpose: {satellite.purpose} <br />
            class of orbit: {satellite.classoforbit} <br />
            type of orbit: {satellite.typeoforbit} <br />
            longetitude geo: {satellite.longetitudegeo} <br />
            perigee: {satellite.perigee} <br />
            apogee: {satellite.apogee} <br />
            eccentricity: {satellite.eccentricity} <br />
            inclination: {satellite.inclination} <br />
            period: {satellite.period} <br />
            launch mass: {satellite.launchmass} <br />
            date of launch: {satellite.dateoflaunch} <br />
            contractor: {satellite.contractor} <br />
            country of contractor: {satellite.countryofcontractor} <br />
            launch site: {satellite.launchsite} <br />
            launch vehicle: {satellite.launchvehicle} <br />
            cospar number: {satellite.cosparnumber} <br />
            norad number: {satellite.noradnumber} <br />
            comments: {satellite.comments} <br />
            source for orbital data: {satellite.sourcefororbitaldata} <br />
            source: {satellite.source} <br />
            </pre>
              </div>
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClosesat} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    ));
  }
  return null; // Return null if there are no satellites
};



 



  return (
    <div>
    
      <div>
       
      <Appbar/>
      <Spacer height="20px" />
      
     <Container  
  sx={{
    borderRadius: '6px',
    border: 'none',
    maxWidth: '98%',
    boxShadow: '0px 0px 100px 7px rgba(0, 0, 0, 0.5)',
    display: 'flex',            // Apply Flexbox to the container
    padding: '20px' //space between last component and the container 
    
  }}
  maxWidth={false}
>
 <Grid container spacing={2}>
 
 
          {/* Repeat this grid item for each MediaCard */}
          <Grid item xs={2}>
           <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
    }}
  >
<Typography variant="h6" sx={{ color: 'white', fontFamily: 'Montserrat', fontWeight: 'bold', fontStyle: 'italic', letterSpacing: '2px' }}>
        <ZoomInIcon sx={{ fontSize: '1.2em', marginRight: '8px', verticalAlign: 'middle' }} /> Advanced search
      </Typography>
</Box>
          </Grid>
          
          <Grid item xs={4}>
          </Grid>
          <Grid item xs={4}>
          </Grid>
           <Grid item xs={12}>
          
           <div
        style={{
          flex: 1,
          borderTop: '2px solid #fff', // White bold line on the right
          marginLeft: '10px', // Adjust the spacing between the text and the line
        }}
      ></div>
      </Grid>
          
          <Grid item xs={4}  container justifyContent="center"  >
          
          <SearchFormId/>
          </Grid>
          
          
          
<Grid item xs={4} container justifyContent="center" alignItems="baseline">

 <Box
      sx={{
        padding: '30px',
        backgroundColor: '#1d1d21',
        borderRadius: 2,
        color: 'white',
        display: 'flex',
        justifyContent: 'space-between',
        verticalAlign: 'baseline' 
      }}
    >

  
  
    <TextField
       label={
    <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'white' , fontFamily: 'Montserrat, sans-serif',}}>
      Entity ID
    </Typography>
  }
      variant="standard"
      color="primary"
      type="text"
      value={searchQuery}
      onChange={handleSearchQueryChange}
      size="small"
      sx={{
        marginRight: '20px',
        width: '100%',
        maxWidth: '200px', // Adjust the max width as needed
        '& .MuiInput-underline:after': {
          borderBottomColor: 'green', // Custom underline color
        },
        
      }}
      inputProps={{
        style: { color: 'white', fontWeight: 'bold' }, // Set the color and font weight of the input text to white and bold
      }}
      InputLabelProps={{
        style: { color: 'white' }, // Set the color of the label text to white
      }}
    />
  
   
      <Button
      style={{
        verticalAlign: 'baseline',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      color="success"
      variant="contained"
      onClick={fetchData}
    >
    <RocketLaunchIcon fontSize="large" />
    </Button>
    
    </Box>
    
</Grid>


          
          <Grid item xs={4}>
          
           <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'white' , fontFamily: 'Montserrat, sans-serif',}}>
      How to use it?
    </Typography>
          <Alert severity="success">
    <strong>Advanced search</strong> is a uniq feature from Recon[.]Space that aggregate the results of different API endpoints based on the <strong>organization name ID</strong> .
    <br/><br/>
    This feature uses <strong>lot of API credits</strong> as it targets multiple endpoints in one go. If your account is not a premium account, please be aware of this limit.
    <br/><br/>
    Search the organization ID based on the organization name <LocationSearchingIcon fontSize="small" />. Then copy and paste the ID in <strong>entity ID</strong> field and launch your requests <RocketLaunchIcon fontSize="small" />. 
</Alert>
</Grid>
          
          
          
        </Grid>

  
</Container>
      </div>
      
      <Spacer height="40px" />
      
  {data.id && (<Container
  sx={{
    borderRadius: '6px',
    border: 'none',
    maxWidth: '98%',
    boxShadow: '0px 0px 100px 7px rgba(0, 0, 0, 0.5)',
    
  }}
  maxWidth={false}
>

  <Grid container spacing={2}>
    <Grid item xs={2}>
    
     {data.id && (<Box
      sx={{
        padding: '8px', // Adjust padding as needed
        borderRadius: '6px',
        alignItems: 'center',
        display: 'inline-block',
      }}
    >
  
      <Typography
  variant="h6"
  sx={{
    fontFamily: 'Montserrat',
    color: 'white',
    alignItems: 'center',
    position: 'relative',
    overflow: 'hidden',
  }}
>
  <span
    style={{
      fontWeight: 'bold',
      display: 'inline-block',
      textShadow: data.spaceweapons && data.spaceweapons.length > 0
        ? '0 0 10px rgba(0, 255, 0, 1)' // Neon effect with green color
        : 'none', // No neon effect
      animation: data.spaceweapons && data.spaceweapons.length > 0
        ? 'neon-flicker 1.5s infinite' // Flickering effect
        : 'none', // No flickering effect
    }}
  >
    {data.spaceweapons && data.spaceweapons.length > 0 ? (
      <SvgIcon
        component={() => (
          <img src={lightsaberIcon} alt="Lightsaber Icon" style={{ width: '40px', height: '40px' }} />
        )}
        sx={{ marginRight: 2, verticalAlign: 'middle' }}
      />
    ) : (
      <SvgIcon
        component={BusinessIcon}
        sx={{ fontSize: 24, color: 'green', marginRight: 2, verticalAlign: 'middle' }}
      />
    )}
    {data.organisationname} - <span style={{ fontWeight: 'bold' }}>{data.id}</span>
  </span>
</Typography>

    </Box>)}
  </Grid>
    
      <Grid item xs={4} >
       {renderTags() && (<Box
    sx={{
      
      padding: '8px', // Adjust padding as needed
        borderRadius: '6px',
        alignItems: 'center',
        display: 'inline-block',
      
    }}
  >
  
      <Typography variant="h6" sx={{ fontFamily: 'Montserrat', color: 'white', alignItems: 'center' }}>
      <SvgIcon component={TagOutlined} sx={{ fontSize: 24, color: 'green', marginRight: 2, verticalAlign: 'middle' }} />
      {renderTags()}
    </Typography>
    </Box>)}
    
       </Grid>
        
        <Grid item xs={4} sx={{  justifyContent: 'center', alignItems: 'center' }}>
         {data.countrycode && (<Box
    sx={{
      padding: '8px', // Adjust padding as needed
      borderRadius: '6px 6px 6px 6px',
      alignItems: 'center',
      display: 'flex',
      
      
    }}
  >
  <SvgIcon component={Flag} sx={{
          fontSize: 24, // Adjust size as needed
          color: 'green', // Vivid color that contrasts with the background
        }} />
      <Typography variant="h6" sx={{ fontFamily: 'Montserrat', color: 'white' }}>
       {data.countrycode}
    </Typography>
    
    </Box>)}
    
    
       </Grid>
      
    <Grid item xs={2}>
     {data.id && ( <Button variant="contained" color="success" onClick={handleOpen}>
        API process flow
      </Button>)}
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          
           <img src={Flowimage} alt="Flow" />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
       {error && <p style={{ color: 'red' }}>{error}</p>}
       
    </Grid>
    
     
    <Grid item xs={6}>
     {data.id && (<Container
  sx={{
    borderRadius: '6px',
    border: 'none',
    maxWidth: '98%',
    boxShadow: '0px 0px 100px 7px rgba(0, 0, 0, 0.5)',
    position: 'relative', // Add this to make it a reference for positioning
    padding: '20px'
    
  }}
  maxWidth={false}
>

 <Grid container spacing={2}>
 <Grid item xs={12} >
 <SvgIcon component={FormatAlignCenterIcon} sx={{ fontSize: 20, color: 'white', marginRight: 5 }} />
<Typography variant="h7" sx={{ fontFamily: 'Montserrat', color: 'white' }}>
        General
    </Typography>
    
 </Grid>
 <Grid item xs={12} >
  <Divider style={{ backgroundColor: 'white', }} />
  </Grid>
<Grid item xs={4}>
  {data.description && (
    <Box
      sx={{
        fontFamily: 'Montserrat',
        color: 'white',
        textAlign: 'justify', 
      }}
    >
      <Typography variant="h7">{data.description}</Typography>
    </Box>
  )}
</Grid>
<Grid item xs={8}>
  {( data.orgtype || data.founded || data.fundingtype || data.totalfunding|| data.employees|| data.creator )? (<TableContainer component={Paper} style={{ borderRadius: '1px',  boxShadow: '10px 4px 8px rgba(0, 75, 0, 0.2)' }}>
      <Table aria-label="simple table" style={{ border: 'none' }}>
        <TableHead>
          <TableRow style={{ backgroundColor: '#0b350b', color: 'white' }}>
            <TableCell style={{ color: 'white', fontWeight: 'bold', fontFamily: 'Montserrat, sans-serif' }}>Fields</TableCell>
            <TableCell align="left" style={{ color: 'white', fontWeight: 'bold', fontFamily: 'Montserrat, sans-serif' }}>Data</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.orgtype && (
            <TableRow style={{ backgroundColor: '#1d1d21' }}>
              <TableCell component="th" scope="row" style={{ color: 'white', fontFamily: 'Montserrat, sans-serif' }}>
                Orgtype
              </TableCell>
              <TableCell align="left" style={{ color: 'white', fontFamily: 'Montserrat, sans-serif' }}>{data.orgtype}</TableCell>
            </TableRow>
          )}
          {data.founded && (
            <TableRow style={{ backgroundColor: '#1d1d21' }}>
              <TableCell component="th" scope="row" style={{ color: 'white', fontFamily: 'Montserrat, sans-serif' }}>
                Founded
              </TableCell>
              <TableCell align="left" style={{ color: 'white', fontFamily: 'Montserrat, sans-serif' }}>{data.founded}</TableCell>
            </TableRow>
          )}
        
          {data.employees && (
            <TableRow style={{ backgroundColor: '#1d1d21' }}>
              <TableCell component="th" scope="row" style={{ color: 'white', fontFamily: 'Montserrat, sans-serif' }}>
                Employees
              </TableCell>
              <TableCell align="left" style={{ color: 'white', fontFamily: 'Montserrat, sans-serif' }}>{data.employees}</TableCell>
            </TableRow>
          )}
          {data.creator && (
            <TableRow style={{ backgroundColor: '#1d1d21' }}>
              <TableCell component="th" scope="row" style={{ color: 'white', fontFamily: 'Montserrat, sans-serif' }}>
                Creator
              </TableCell>
              <TableCell align="left" style={{ color: 'white', fontFamily: 'Montserrat, sans-serif' }}>{data.creator}</TableCell>
            </TableRow>
          )}
          
          {data.websiteurl && (
            <TableRow style={{ backgroundColor: '#1d1d21' }}>
              <TableCell component="th" scope="row" style={{ color: 'white', fontFamily: 'Montserrat, sans-serif' }}>
                WWW
              </TableCell>
              <TableCell align="left" style={{ color: 'white', fontFamily: 'Montserrat, sans-serif' }}>{data.websiteurl}</TableCell>
            </TableRow>
          )}
                  
          {(data.linkedinurl || data.twitterurl || data.facebookurl) && (
            <TableRow style={{ backgroundColor: '#1d1d21' }}>
              <TableCell component="th" scope="row" style={{ color: 'white', fontFamily: 'Montserrat, sans-serif' }}>
            Social
          </TableCell>
          <TableCell align="left" style={{ color: 'white', fontFamily: 'Montserrat, sans-serif' }}>
            <IconButton component="a" href={data.linkedinurl} target="_blank" rel="noopener noreferrer">
              <LinkedInIcon style={{ color: 'white' }} />
            </IconButton>
            <IconButton component="a" href={data.facebookurl} target="_blank" rel="noopener noreferrer">
              <FacebookIcon style={{ color: 'white' }} />
            </IconButton>
            <IconButton component="a" href={data.twitterurl} target="_blank" rel="noopener noreferrer">
              <TwitterIcon style={{ color: 'white' }} />
            </IconButton>
          </TableCell>
            </TableRow>
          )}
          
          {data.contactemail && (
            <TableRow style={{ backgroundColor: '#1d1d21' }}>
              <TableCell component="th" scope="row" style={{ color: 'white', fontFamily: 'Montserrat, sans-serif' }}>
                HR email
              </TableCell>
              <TableCell align="left" style={{ color: 'white', fontFamily: 'Montserrat, sans-serif' }}>{data.contactemail}</TableCell>
            </TableRow>
          )}
          
          {data.contactphone && (
            <TableRow style={{ backgroundColor: '#1d1d21' }}>
              <TableCell component="th" scope="row" style={{ color: 'white', fontFamily: 'Montserrat, sans-serif' }}>
                HR phone
              </TableCell>
              <TableCell align="left" style={{ color: 'white', fontFamily: 'Montserrat, sans-serif' }}>{data.contactphone}</TableCell>
            </TableRow>
          )}
          
          {data.websitemontlyvisits && (
            <TableRow style={{ backgroundColor: '#1d1d21' }}>
              <TableCell component="th" scope="row" style={{ color: 'white', fontFamily: 'Montserrat, sans-serif' }}>
                Website visits by month
              </TableCell>
              <TableCell align="left" style={{ color: 'white', fontFamily: 'Montserrat, sans-serif' }}>{data.websitemontlyvisits}</TableCell>
            </TableRow>
          )}
          
          {data.lastleadershiphiringdate && (
            <TableRow style={{ backgroundColor: '#1d1d21' }}>
              <TableCell component="th" scope="row" style={{ color: 'white', fontFamily: 'Montserrat, sans-serif' }}>
                Last leader ship hiring date
              </TableCell>
              <TableCell align="left" style={{ color: 'white', fontFamily: 'Montserrat, sans-serif' }}>{data.lastleadershiphiringdate}</TableCell>
            </TableRow>
          )}
          
          {data.patentsgranted && (
            <TableRow style={{ backgroundColor: '#1d1d21' }}>
              <TableCell component="th" scope="row" style={{ color: 'white', fontFamily: 'Montserrat, sans-serif' }}>
                Patents granted
              </TableCell>
              <TableCell align="left" style={{ color: 'white', fontFamily: 'Montserrat, sans-serif' }}>{data.patentsgranted}</TableCell>
            </TableRow>
          )}
          
          {data.trademarksregistered && (
            <TableRow style={{ backgroundColor: '#1d1d21' }}>
              <TableCell component="th" scope="row" style={{ color: 'white', fontFamily: 'Montserrat, sans-serif' }}>
                Trademarks registered
              </TableCell>
              <TableCell align="left" style={{ color: 'white', fontFamily: 'Montserrat, sans-serif' }}>{data.trademarksregistered}</TableCell>
            </TableRow>
          )}
          
          {/* Add more rows as needed */}
        </TableBody>
      </Table>
    </TableContainer>) : (
    <Box>
    </Box>
  )}
</Grid>
</Grid>
<div id="tableContainer"></div>



</Container>)}
     
    </Grid>
    
    
    {userType === 'anon' && (<Grid item xs={5} container justifyContent="center">
   
<img src={Restrictedimage} alt="Lightsaber Icon" style={{ width: '600px', height: '500px' }} />


    </Grid>)}
    
  <Grid item xs={4} container justifyContent="center">
    {data.domain && (<Container
  sx={{
    borderRadius: '6px',
    border: 'none',
    maxWidth: '98%',
    boxShadow: '0px 0px 100px 7px rgba(0, 0, 0, 0.5)',
    position: 'relative', // Add this to make it a reference for positioning
    padding: '20px'
    
  }}
  maxWidth={false}
>

 <Grid container spacing={2}>
 <Grid item xs={12} >
 <SvgIcon component={ComputerIcon} sx={{ fontSize: 20, color: 'white', marginRight: 5 }} />
<Typography variant="h7" sx={{ fontFamily: 'Montserrat', color: 'white' }}>
        Technical
    </Typography>
    
 </Grid>
 <Grid item xs={12} >
  <Divider style={{ backgroundColor: 'white', }} />
  </Grid>
 
<Grid item xs={12} >
   {data.domain && (<Box
  sx={{
    padding: '8px',
    borderRadius: '6px 6px 0 0',
    display: 'flex',
    flexDirection: 'column', // Arrange the elements vertically
    alignItems: 'flex-start', // Align items to the left
  }}
>
  <Typography
    variant="h7"
    sx={{
      fontFamily: 'Montserrat',
      color: 'white',
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      padding: '5px',
      borderRadius: '5px',
      marginBottom: '10px', // Add spacing between Typography and Buttons
      width: '100%', // Center the Typography horizontally
      textAlign: 'center', // Center the text
    }}
  >
    Domains
  </Typography>
  
    <Button
      variant="contained"
      color="primary"
      sx={{
        backgroundColor: 'transparent',
        padding: '5px',
        borderRadius: '5px',
        marginRight: '5px',
        '&:hover': {
          backgroundColor: '#0b350b',
          
        },
      }}
    >
      {data.domain}
    </Button>
  
  {/* Additional Buttons can be added here */}
</Box>)}

</Grid>


<Grid item xs={12}>
  {renderSubdomains() && (<Box
  sx={{
    padding: '8px',
    borderRadius: '6px 6px 0 0',
    display: 'flex',
    flexDirection: 'column', // Arrange the elements vertically
    alignItems: 'center', // Align items to the center horizontally
  }}
>
  <Typography
    variant="h7"
    sx={{
      fontFamily: 'Montserrat',
      color: 'white',
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      padding: '5px',
      borderRadius: '5px',
      marginBottom: '10px', // Add spacing between Typography and Buttons
      width: '100%', // Center the Typography horizontally
      textAlign: 'center', // Center the text
    }}
  >
    Subdomains
  </Typography>
   <div
    style={{
      display: 'flex',
      flexDirection: 'row', // Arrange buttons side by side
      flexWrap: 'wrap', // Allow buttons to wrap to the next line if necessary
      justifyContent: 'left', // Center the buttons horizontally
    }}
  >
    {renderSubdomains()}
  </div>
</Box>
 )}
 </Grid>

<Grid item xs={12}>
  {renderTechnologies() !== null && renderTechnologies().length > 0 && (<Box
  sx={{
    padding: '8px',
    borderRadius: '6px 6px 0 0',
    display: 'flex',
    flexDirection: 'column', // Arrange the elements vertically
    alignItems: 'center', // Align items to the center horizontally
  }}
>
  <Typography
    variant="h7"
    sx={{
      fontFamily: 'Montserrat',
      color: 'white',
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      padding: '5px',
      borderRadius: '5px',
      marginBottom: '10px', // Add spacing between Typography and Buttons
      width: '100%', // Center the Typography horizontally
      textAlign: 'center', // Center the text
    }}
  >
    Technologies
  </Typography>
  
<div
  style={{
    display: 'flex',
    flexDirection: 'row', // Arrange buttons side by side
    flexWrap: 'wrap', // Allow buttons to wrap to the next line if necessary
    justifyContent: 'center', // Align the buttons to the left
  }}
>
  {renderTechnologies()}
</div>
</Box>
 )}
 </Grid>

<Grid item xs={12}>
 {renderIPs() && (
  
   
    <Box
  sx={{
    padding: '8px',
    borderRadius: '6px 6px 0 0',
    display: 'flex',
    flexDirection: 'column', // Arrange the elements vertically
    alignItems: 'center', // Align items to the center horizontally
  }}
>
  <Typography
    variant="h7"
    sx={{
      fontFamily: 'Montserrat',
      color: 'white',
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      padding: '5px',
      borderRadius: '5px',
      marginBottom: '10px', // Add spacing between Typography and Buttons
      width: '100%', // Center the Typography horizontally
      textAlign: 'center', // Center the text
    }}
  >
    IP addresses
  </Typography>
  <div
    style={{
      display: 'flex',
      flexDirection: 'row', // Arrange buttons side by side
      flexWrap: 'wrap', // Allow buttons to wrap to the next line if necessary
      justifyContent: 'center', // Center the buttons horizontally
    }}
  >
    {renderIPs()}
  </div>
</Box>
 )}
 
</Grid>

</Grid>

</Container>)}


  
</Grid>


<Grid item xs={2}>
{renderSatellites() && (<Container
  sx={{
    borderRadius: '6px',
    border: 'none',
    maxWidth: '98%',
    boxShadow: '0px 0px 100px 7px rgba(0, 0, 0, 0.5)',
    position: 'relative', // Add this to make it a reference for positioning
    padding: '20px'
    
  }}
  maxWidth={false}
>

 <Grid container spacing={2}>
 <Grid item xs={12} >
 <SvgIcon component={SatelliteAltIcon} sx={{ fontSize: 20, color: 'white', marginRight: 5 }} />
<Typography variant="h7" sx={{ fontFamily: 'Montserrat', color: 'white' }}>
        Satellites
    </Typography>
    
 </Grid>
 <Grid item xs={12} >
  <Divider style={{ backgroundColor: 'white', }} />
  </Grid>
 
<Grid item xs={12} >

    {renderSatellites()}

  </Grid>
  </Grid>
</Container>)}
</Grid>

    
  </Grid>
  <Spacer height="40px" />
</Container>)}

<Spacer height="40px" />

{datafinancial !== null && Object.keys(datafinancial).length > 0 &&(<Container
  sx={{
    borderRadius: '6px',
    border: 'none',
    maxWidth: '98%',
    boxShadow: '0px 0px 100px 7px rgba(0, 0, 0, 0.5)',
    position: 'relative', // Add this to make it a reference for positioning
    padding: '20px'
    
  }}
  maxWidth={false}
>
<Grid container spacing={2}>
 
 <Grid item xs={12} >
 <SvgIcon component={AttachMoneyIcon} sx={{ fontSize: 20, color: 'white', marginRight: 5 }} />
<Typography variant="h7" sx={{ fontFamily: 'Montserrat', color: 'white' }}>
        Financial data | Market analysis
    </Typography>
    
 </Grid>
 
 <Grid item xs={12} >
  <Divider style={{ backgroundColor: 'white', }} />
  </Grid>
 
    <Grid item xs={12}>
{renderFinancial()}
</Grid>
</Grid>

</Container>)}

<Spacer height="40px" />

{data.id && (<Container  
  sx={{
    borderRadius: '6px',
    border: 'none',
    maxWidth: '98%',
    boxShadow: '0px 0px 100px 7px rgba(0, 0, 0, 0.5)',
    display: 'flex',            // Apply Flexbox to the container
    padding: '20px' //space between last component and the container 
    
  }}
  maxWidth={false}
>

 <Grid container spacing={2}>
 
 <Grid item xs={12} >
 <SvgIcon component={GavelIcon} sx={{ fontSize: 20, color: 'white', marginRight: 5 }} />
<Typography variant="h7" sx={{ fontFamily: 'Montserrat', color: 'white' }}>
        Laws | Guidelines
    </Typography>
    
 </Grid>
 
 <Grid item xs={12} >
  <Divider style={{ backgroundColor: 'white', }} />
  </Grid>
 
    <Grid item xs={12}>
{renderLaws()}
</Grid>

</Grid>

</Container>)}
<Spacer height="40px" />



      
            <Spacer height="40px" />
      <Footer />
      <Spacer height="3px" />
    </div>
  );
};

export default App;

import React, { useState, useEffect, useCallback,useMemo } from 'react';
import image1 from './images/intellnote.png';
import image2 from './images/GPS-location.png';
import image3 from './images/SpaceX.png';
import image4 from './images/Laws-Graph.png';

import { ArrowBack, ArrowForward } from '@mui/icons-material'; // Import MUI icons
import './css/Carousel.css'; // Import the CSS file

const Carousel = () => {
  const carouselData = useMemo(() => [
    {
      id: 1,
      title: 'Intelligence note',
      caption: 'Get technical informations using the /subdomain API endpoint.',
      imageUrl: image1,
    },
    {
      id: 2,
      title: 'Map',
      caption: 'Recon[.]Space provides GPS location of every space entity.',
      imageUrl: image2,
    },
    {
      id: 3,
      title: 'Entity details',
      caption: 'Advanced search feature from Recon[.]Space.',
      imageUrl: image3,
    },
    {
      id: 4,
      title: 'Laws details',
      caption: 'Details about Laws by entity are presented as well in advanced search.',
      imageUrl: image4,
    },
    // Add more items as needed
  ], []);

  const [currentItem, setCurrentItem] = useState(0);

  const handleNext = useCallback(() => {
    setCurrentItem((prevItem) => (prevItem + 1) % carouselData.length);
  }, [carouselData]);

  const handlePrev = useCallback(() => {
    setCurrentItem((prevItem) =>
      prevItem === 0 ? carouselData.length - 1 : prevItem - 1
    );
  }, [carouselData]);

  useEffect(() => {
  const intervalId = setInterval(handleNext, 5000);

  // Clear the interval when the component is unmounted
  return () => clearInterval(intervalId);
}, [currentItem, carouselData.length, handleNext]);

  return (
  
    <div className="carousel-container" >
      <div className="carousel">
        <div className="image-container">
          <img src={carouselData[currentItem].imageUrl} alt={carouselData[currentItem].title} />
        </div>
        <div className="text-container">
          <h2>{carouselData[currentItem].title}</h2>
          <p>{carouselData[currentItem].caption}</p>
        </div>
        <button className="navButtoni left" onClick={handlePrev}>
          <ArrowBack />
        </button>
        <button className="navButtoni right" onClick={handleNext}>
          <ArrowForward />
        </button>
      </div>
    </div>
  );
};

export default Carousel;

import React, { useState } from 'react';
import { Grid, TextField, Typography } from '@mui/material';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import satelliteIcon from './images/satellite.png';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import apiBaseUrl from '../Apiconfig.js';

const useStyles = {
  formContainer: {
    padding: '20px',
    textAlign: 'center',
    borderRadius: '8px', // Optional: Add border radius for rounded corners
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.5)', 
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  textField: {
    margin: '10px 0',
    width: '100%',
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green', // Custom underline color
    },
  },
  button: {
    marginTop: '20px',
  },
};

const RequestsOrganization = () => {
  const [formData, setFormData] = useState({
    officialname: '',
    countrycode: '',
    countryoperator: '',
    operator: '',
    users: '',
    purpose: '',
    detailedpurpose: '',
    classoforbit: '',
    typeoforbit: '',
    longetitudegeo: '',
    perigee: '',
    apogee: '',
    eccentricity: '',
    inclination: '',
    launchmass: '',
    drymass: '',
    powerwatt: '',
    dateoflaunch: '',
    expectedlifetime: '',
    contractor: '',
    countryofcontractor: '',
    launchsite: '',
    launchvehicle: '',
    cosparnumber: '',
    noradnumber: '',
    comments: '',
    sourcefororbitaldata: '',
    source: '',
    
  });

  const [recaptchaValue, setRecaptchaValue] = useState(null);

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if reCAPTCHA is ticked
    if (!recaptchaValue) {
      alert('Please complete the reCAPTCHA verification.');
      return;
    }

    try {
      // Remove any formatting characters (e.g., commas) from the funding value
      

      // Update the formData with the parsed value
      setFormData({
        ...formData,
      });

      console.log('Submitting data:', formData);
      const endpoint = `${apiBaseUrl}/satellite-requests/`;
      const response = await axios.post(endpoint, formData);

      console.log('Response from server:', response);

      if (response.status === 201) {
        alert('Satellite request submitted successfully!');
        // Handle success, e.g., redirect or show a success message
      } else {
        alert('Failed to submit satellite request. Please try again.');
        console.error('Server response:', response.data);
        // Handle error, e.g., show an error message
      }
    } catch (error) {
      alert('Error submitting satellite request. Please try again.');
      console.error('Error submitting satellite request:', error);
      console.log('Server response:', error.response);
    }
  };

  return (
  
   
    <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
      <Grid item xs={10} sm={8} md={6} style={useStyles.formContainer}>
        <Typography variant="h6" sx={{ color: 'white', fontFamily: 'Montserrat', fontWeight: 'bold', fontStyle: 'italic', letterSpacing: '2px' }}>
        <img src={satelliteIcon} alt="satellite icon" style={{ width: '40px', height: '40px' }} /> Submit a satellite
      </Typography>
        <form style={useStyles.form} onSubmit={handleSubmit}>
        
        
   <TextField
  className={useStyles.customTextField}
  label={
    <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'white', fontFamily: 'Montserrat, sans-serif' }}>
      Official Satellite Name
    </Typography>
  }
  variant="standard"
  size="small"
  name="officialname"
  value={formData.officialname}
  onChange={handleInputChange}
  required
  sx={{
    width: '100%',
   maxWidth: '600px',
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiInputLabel-root': {
      color: 'white',
    },
    '& .MuiInputLabel-asterisk': {
      display: 'none', // Hide the asterisk
    },
  }}
  inputProps={{
    style: { color: 'white', borderColor: '#9d9d9d', borderWidth: '2px' },
  }}
/>

          <TextField
  className={useStyles.customTextField}
  label={
    <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'white', fontFamily: 'Montserrat, sans-serif' }}>
      Country Code
    </Typography>
  }
  variant="standard"
  size="small"
  name="countrycode"
  value={formData.countrycode}
  onChange={handleInputChange}
  required
  sx={{
    width: '100%',
   maxWidth: '600px',
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiInputLabel-root': {
      color: 'white',
    },
    '& .MuiInputLabel-asterisk': {
      display: 'none', // Hide the asterisk
    },
  }}
  inputProps={{
    style: { color: 'white', borderColor: '#9d9d9d', borderWidth: '2px' },
  }}
/>

          <TextField
  className={useStyles.customTextField}
  label={
    <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'white', fontFamily: 'Montserrat, sans-serif' }}>
      Country Operator
    </Typography>
  }
  variant="standard"
  size="small"
  name="countryoperator"
  value={formData.countryoperator}
  onChange={handleInputChange}
  required
  sx={{
    width: '100%',
    maxWidth: '600px',
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiInputLabel-root': {
      color: 'white',
    },
    '& .MuiInputLabel-asterisk': {
      display: 'none', // Hide the asterisk
    },
  }}
  inputProps={{
    style: { color: 'white', borderColor: '#9d9d9d', borderWidth: '2px' },
  }}
/>

<TextField
  className={useStyles.customTextField}
  label={
    <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'white', fontFamily: 'Montserrat, sans-serif' }}>
     Operator (Company/agency)
    </Typography>
  }
  variant="standard"
  size="small"
  name="operator"
  value={formData.operator}
  onChange={handleInputChange}
  required
  sx={{
    width: '100%',
    maxWidth: '600px',
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiInputLabel-root': {
      color: 'white',
    },
    '& .MuiInputLabel-asterisk': {
      display: 'none', // Hide the asterisk
    },
  }}
  inputProps={{
    style: { color: 'white', borderColor: '#9d9d9d', borderWidth: '2px' },
  }}
/>


          <TextField
  className={useStyles.customTextField}
  label={
    <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'white', fontFamily: 'Montserrat, sans-serif' }}>
      Users
    </Typography>
  }
  variant="standard"
  size="small"
  name="users"
  value={formData.users}
  onChange={handleInputChange}
  sx={{
    width: '100%',
    maxWidth: '600px',
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiInputLabel-root': {
      color: 'white',
    },
    '& .MuiInputLabel-asterisk': {
      display: 'none', // Hide the asterisk
    },
  }}
  inputProps={{
    style: { color: 'white', borderColor: '#9d9d9d', borderWidth: '2px' },
  }}
/>
          <TextField
  className={useStyles.customTextField}
  label={
    <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'white', fontFamily: 'Montserrat, sans-serif' }}>
      Purpose
    </Typography>
  }
  variant="standard"
  size="small"
  type="text"
  name="purpose"
  value={formData.purpose}
  onChange={handleInputChange}
  required
  sx={{
    width: '100%',
   maxWidth: '600px',
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiInputLabel-root': {
      color: 'white',
    },
    '& .MuiInputLabel-asterisk': {
      display: 'none', // Hide the asterisk
    },
  }}
  inputProps={{
    style: { color: 'white', borderColor: '#9d9d9d', borderWidth: '2px' },
  }}
/>


          <TextField
  className={useStyles.customTextField}
  label={
    <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'white', fontFamily: 'Montserrat, sans-serif' }}>
      Detailed purpose
    </Typography>
  }
  variant="standard"
  size="small"
  name="detailedpurpose"
  value={formData.detailedpurpose}
  onChange={handleInputChange}
  multiline
  rows={4}
  sx={{
    width: '100%',
    maxWidth: '600px',
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiInputLabel-root': {
      color: 'white',
    },
    '& .MuiInputLabel-asterisk': {
      display: 'none', // Hide the asterisk
    },
  }}
  inputProps={{
    style: { color: 'white', borderColor: '#9d9d9d', borderWidth: '2px' },
  }}
/>

         <TextField
  className={useStyles.customTextField}
  label={
    <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'white', fontFamily: 'Montserrat, sans-serif' }}>
      Class of orbit
    </Typography>
  }
  variant="standard"
  size="small"
  name="classoforbit"
  value={formData.classoforbit}
  onChange={handleInputChange}
  required
  sx={{
    width: '100%',
    maxWidth: '600px',
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiInputLabel-root': {
      color: 'white',
    },
    '& .MuiInputLabel-asterisk': {
      display: 'none', // Hide the asterisk
    },
  }}
  inputProps={{
    style: { color: 'white', borderColor: '#9d9d9d', borderWidth: '2px' },
  }}
/>


    <TextField
  className={useStyles.customTextField}
  label={
    <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'white', fontFamily: 'Montserrat, sans-serif' }}>
      Type of orbit
    </Typography>
  }
  variant="standard"
  size="small"
  
  name="typeoforbit"
  value={formData.typeoforbit}
  onChange={handleInputChange}
  required
  sx={{
    width: '100%',
    maxWidth: '600px',
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiInputLabel-root': {
      color: 'white',
    },
    '& .MuiInputLabel-asterisk': {
      display: 'none', // Hide the asterisk
    },
  }}
  inputProps={{
    style: { color: 'white', borderColor: '#9d9d9d', borderWidth: '2px' },
  }}
/>



          <TextField
  className={useStyles.customTextField}
  label={
    <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'white', fontFamily: 'Montserrat, sans-serif' }}>
      Longetide geo
    </Typography>
  }
  variant="standard"
  size="small"
  name="longetitudegeo"
  value={formData.longetitudegeo}
  onChange={handleInputChange}
  sx={{
    width: '100%',
    maxWidth: '600px',
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiInputLabel-root': {
      color: 'white',
    },
    '& .MuiInputLabel-asterisk': {
      display: 'none', // Hide the asterisk
    },
  }}
  inputProps={{
    style: { color: 'white', borderColor: '#9d9d9d', borderWidth: '2px' },
  }}
/>

       <TextField
  className={useStyles.customTextField}
  label={
    <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'white', fontFamily: 'Montserrat, sans-serif' }}>
      Perigee
    </Typography>
  }
  variant="standard"
  size="small"
  name="perigee"
  value={formData.perigee}
  onChange={handleInputChange}
  required
  sx={{
    width: '100%',
    maxWidth: '600px',
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiInputLabel-root': {
      color: 'white',
    },
    '& .MuiInputLabel-asterisk': {
      display: 'none', // Hide the asterisk
    },
  }}
  inputProps={{
    style: { color: 'white', borderColor: '#9d9d9d', borderWidth: '2px' },
  }}
/>
       <TextField
  className={useStyles.customTextField}
  label={
    <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'white', fontFamily: 'Montserrat, sans-serif' }}>
      Apogee
    </Typography>
  }
  variant="standard"
  size="small"
  name="apogee"
  value={formData.apogee}
  onChange={handleInputChange}
  required
  sx={{
    width: '100%',
    maxWidth: '600px',
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiInputLabel-root': {
      color: 'white',
    },
    '& .MuiInputLabel-asterisk': {
      display: 'none', // Hide the asterisk
    },
  }}
  inputProps={{
    style: { color: 'white', borderColor: '#9d9d9d', borderWidth: '2px' },
  }}
/>

       <TextField
  className={useStyles.customTextField}
  label={
    <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'white', fontFamily: 'Montserrat, sans-serif' }}>
      Eccentricity
    </Typography>
  }
  variant="standard"
  size="small"
  name="eccentricity"
  value={formData.eccentricity}
  onChange={handleInputChange}
  required
  sx={{
    width: '100%',
    maxWidth: '600px',
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiInputLabel-root': {
      color: 'white',
    },
    '& .MuiInputLabel-asterisk': {
      display: 'none', // Hide the asterisk
    },
  }}
  inputProps={{
    style: { color: 'white', borderColor: '#9d9d9d', borderWidth: '2px' },
  }}
/>

       <TextField
  className={useStyles.customTextField}
  label={
    <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'white', fontFamily: 'Montserrat, sans-serif' }}>
      Inclination
    </Typography>
  }
  variant="standard"
  size="small"
  name="inclination"
  value={formData.inclination}
  onChange={handleInputChange}
  required
  sx={{
    width: '100%',
    maxWidth: '600px',
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiInputLabel-root': {
      color: 'white',
    },
    '& .MuiInputLabel-asterisk': {
      display: 'none', // Hide the asterisk
    },
  }}
  inputProps={{
    style: { color: 'white', borderColor: '#9d9d9d', borderWidth: '2px' },
  }}
/>

       <TextField
  className={useStyles.customTextField}
  label={
    <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'white', fontFamily: 'Montserrat, sans-serif' }}>
      Period
    </Typography>
  }
  variant="standard"
  size="small"
  name="period"
  value={formData.period}
  onChange={handleInputChange}
  required
  sx={{
    width: '100%',
    maxWidth: '600px',
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiInputLabel-root': {
      color: 'white',
    },
    '& .MuiInputLabel-asterisk': {
      display: 'none', // Hide the asterisk
    },
  }}
  inputProps={{
    style: { color: 'white', borderColor: '#9d9d9d', borderWidth: '2px' },
  }}
/>

       <TextField
  className={useStyles.customTextField}
  label={
    <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'white', fontFamily: 'Montserrat, sans-serif' }}>
      Launch mass
    </Typography>
  }
  variant="standard"
  size="small"
  name="launchmass"
  value={formData.launchmass}
  onChange={handleInputChange}
  sx={{
    width: '100%',
    maxWidth: '600px',
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiInputLabel-root': {
      color: 'white',
    },
    '& .MuiInputLabel-asterisk': {
      display: 'none', // Hide the asterisk
    },
  }}
  inputProps={{
    style: { color: 'white', borderColor: '#9d9d9d', borderWidth: '2px' },
  }}
/>

       <TextField
  className={useStyles.customTextField}
  label={
    <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'white', fontFamily: 'Montserrat, sans-serif' }}>
      Dry mass
    </Typography>
  }
  variant="standard"
  size="small"
  name="drymass"
  value={formData.drymass}
  onChange={handleInputChange}
  sx={{
    width: '100%',
    maxWidth: '600px',
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiInputLabel-root': {
      color: 'white',
    },
    '& .MuiInputLabel-asterisk': {
      display: 'none', // Hide the asterisk
    },
  }}
  inputProps={{
    style: { color: 'white', borderColor: '#9d9d9d', borderWidth: '2px' },
  }}
/>

       <TextField
  className={useStyles.customTextField}
  label={
    <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'white', fontFamily: 'Montserrat, sans-serif' }}>
      Power watt
    </Typography>
  }
  variant="standard"
  size="small"
  name="powerwatt"
  value={formData.powerwatt}
  onChange={handleInputChange}
  sx={{
    width: '100%',
    maxWidth: '600px',
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiInputLabel-root': {
      color: 'white',
    },
    '& .MuiInputLabel-asterisk': {
      display: 'none', // Hide the asterisk
    },
  }}
  inputProps={{
    style: { color: 'white', borderColor: '#9d9d9d', borderWidth: '2px' },
  }}
/>

       <TextField
  className={useStyles.customTextField}
  label={
    <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'white', fontFamily: 'Montserrat, sans-serif' }}>
      Date of launch
    </Typography>
  }
  variant="standard"
  size="small"
  name="dateoflaunch"
  type="date"
  value={formData.dateoflaunch}
  onChange={handleInputChange}
  required
   InputLabelProps={{
    shrink: true,
  }}
  sx={{
    width: '100%',
    maxWidth: '600px',
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiInputLabel-root': {
      color: 'white',
    },
    '& .MuiInputLabel-asterisk': {
      display: 'none', // Hide the asterisk
    },
  }}
  inputProps={{
    style: { color: 'white', borderColor: '#9d9d9d', borderWidth: '2px' },
  }}
/>

       <TextField
  className={useStyles.customTextField}
  label={
    <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'white', fontFamily: 'Montserrat, sans-serif' }}>
      Expected life time
    </Typography>
  }
  variant="standard"
  size="small"
  name="expectedlifetime"
  value={formData.expectedlifetime}
  onChange={handleInputChange}
  required
  sx={{
    width: '100%',
    maxWidth: '600px',
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiInputLabel-root': {
      color: 'white',
    },
    '& .MuiInputLabel-asterisk': {
      display: 'none', // Hide the asterisk
    },
  }}
  inputProps={{
    style: { color: 'white', borderColor: '#9d9d9d', borderWidth: '2px' },
  }}
/>

       <TextField
  className={useStyles.customTextField}
  label={
    <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'white', fontFamily: 'Montserrat, sans-serif' }}>
      Contractor
    </Typography>
  }
  variant="standard"
  size="small"
  name="contractor"
  value={formData.contractor}
  onChange={handleInputChange}
  required
  sx={{
    width: '100%',
    maxWidth: '600px',
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiInputLabel-root': {
      color: 'white',
    },
    '& .MuiInputLabel-asterisk': {
      display: 'none', // Hide the asterisk
    },
  }}
  inputProps={{
    style: { color: 'white', borderColor: '#9d9d9d', borderWidth: '2px' },
  }}
/>

       <TextField
  className={useStyles.customTextField}
  label={
    <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'white', fontFamily: 'Montserrat, sans-serif' }}>
      Country of contractor
    </Typography>
  }
  variant="standard"
  size="small"
  name="countryofcontractor"
  value={formData.countryofcontractor}
  onChange={handleInputChange}
  required
  sx={{
    width: '100%',
    maxWidth: '600px',
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiInputLabel-root': {
      color: 'white',
    },
    '& .MuiInputLabel-asterisk': {
      display: 'none', // Hide the asterisk
    },
  }}
  inputProps={{
    style: { color: 'white', borderColor: '#9d9d9d', borderWidth: '2px' },
  }}
/>

       <TextField
  className={useStyles.customTextField}
  label={
    <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'white', fontFamily: 'Montserrat, sans-serif' }}>
      Launch site
    </Typography>
  }
  variant="standard"
  size="small"
  name="launchsite"
  value={formData.launchsite}
  onChange={handleInputChange}
  required
  sx={{
    width: '100%',
    maxWidth: '600px',
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiInputLabel-root': {
      color: 'white',
    },
    '& .MuiInputLabel-asterisk': {
      display: 'none', // Hide the asterisk
    },
  }}
  inputProps={{
    style: { color: 'white', borderColor: '#9d9d9d', borderWidth: '2px' },
  }}
/>

       <TextField
  className={useStyles.customTextField}
  label={
    <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'white', fontFamily: 'Montserrat, sans-serif' }}>
      Launch vehicle
    </Typography>
  }
  variant="standard"
  size="small"
  name="launchvehicle"
  value={formData.launchvehicle}
  onChange={handleInputChange}
  required
  sx={{
    width: '100%',
    maxWidth: '600px',
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiInputLabel-root': {
      color: 'white',
    },
    '& .MuiInputLabel-asterisk': {
      display: 'none', // Hide the asterisk
    },
  }}
  inputProps={{
    style: { color: 'white', borderColor: '#9d9d9d', borderWidth: '2px' },
  }}
/>

<TextField
  className={useStyles.customTextField}
  label={
    <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'white', fontFamily: 'Montserrat, sans-serif' }}>
      Cospar number
    </Typography>
  }
  variant="standard"
  size="small"
  name="cosparnumber"
  value={formData.cosparnumber}
  onChange={handleInputChange}
  required
  sx={{
    width: '100%',
    maxWidth: '600px',
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiInputLabel-root': {
      color: 'white',
    },
    '& .MuiInputLabel-asterisk': {
      display: 'none', // Hide the asterisk
    },
  }}
  inputProps={{
    style: { color: 'white', borderColor: '#9d9d9d', borderWidth: '2px' },
  }}
/>

<TextField
  className={useStyles.customTextField}
  label={
    <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'white', fontFamily: 'Montserrat, sans-serif' }}>
      Norad number
    </Typography>
  }
  variant="standard"
  size="small"
  name="noradnumber"
  value={formData.noradnumber}
  onChange={handleInputChange}
  required
  sx={{
    width: '100%',
    maxWidth: '600px',
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiInputLabel-root': {
      color: 'white',
    },
    '& .MuiInputLabel-asterisk': {
      display: 'none', // Hide the asterisk
    },
  }}
  inputProps={{
    style: { color: 'white', borderColor: '#9d9d9d', borderWidth: '2px' },
  }}
/>

<TextField
  className={useStyles.customTextField}
  label={
    <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'white', fontFamily: 'Montserrat, sans-serif' }}>
      Comments
    </Typography>
  }
  variant="standard"
  size="small"
  name="comments"
  value={formData.comments}
  onChange={handleInputChange}
  sx={{
    width: '100%',
    maxWidth: '600px',
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiInputLabel-root': {
      color: 'white',
    },
    '& .MuiInputLabel-asterisk': {
      display: 'none', // Hide the asterisk
    },
  }}
  inputProps={{
    style: { color: 'white', borderColor: '#9d9d9d', borderWidth: '2px' },
  }}
/>

<TextField
  className={useStyles.customTextField}
  label={
    <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'white', fontFamily: 'Montserrat, sans-serif' }}>
      Source for orbital data
    </Typography>
  }
  variant="standard"
  size="small"
  name="sourcefororbitaldata"
  value={formData.sourcefororbitaldata}
  onChange={handleInputChange}
  required
  sx={{
    width: '100%',
    maxWidth: '600px',
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiInputLabel-root': {
      color: 'white',
    },
    '& .MuiInputLabel-asterisk': {
      display: 'none', // Hide the asterisk
    },
  }}
  inputProps={{
    style: { color: 'white', borderColor: '#9d9d9d', borderWidth: '2px' },
  }}
/>


          <TextField
  className={useStyles.customTextField}
  label={
    <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'white', fontFamily: 'Montserrat, sans-serif' }}>
      Source
    </Typography>
  }
  variant="standard"
  size="small"
  name="source"
  value={formData.source}
  onChange={handleInputChange}
  required
  sx={{
    width: '100%',
    maxWidth: '600px',
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiInputLabel-root': {
      color: 'white',
    },
    '& .MuiInputLabel-asterisk': {
      display: 'none', // Hide the asterisk
    },
  }}
  inputProps={{
    style: { color: 'white', borderColor: '#9d9d9d', borderWidth: '2px' },
  }}
/>


          {/* reCAPTCHA */}
          <ReCAPTCHA
            sitekey="6Lcqq3soAAAAAIX0Y4Lbfm9x9lgQMKajthY0EqSy"
            onChange={handleRecaptchaChange}
          />

          {/* Submit Button */}
           <button
  type="submit"
  style={{
    backgroundColor: '#125212',
    fontSize: '1.5rem', // Adjust the font size to make the button bigger
    padding: '10px 20px', // Adjust the padding for more size
    borderRadius: '8px', // Add border-radius for a rounded appearance
    color: 'white', // Text color
    border: 'none', // Remove default button border
    cursor: 'pointer', // Show a pointer cursor on hover
  }}
>
  <RocketLaunchIcon fontSize="large" /> Submit
</button>
        </form>
      </Grid>
    </Grid>
  );
};

export default RequestsOrganization;


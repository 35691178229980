import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import AuthContext  from '../utils/AuthContext'; // Update with the actual path

const PrivateRoute = ({ element }) => {
  const { user } = useContext(AuthContext);

  return user ? (
    element
  ) : (
    <>
      {window.alert('You need to log in to access this page.')}
      <Navigate to="/" />
    </>
  );
};

export default PrivateRoute;


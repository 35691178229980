import { createContext, useState, useEffect } from 'react'
import { jwtDecode } from "jwt-decode";
import { useNavigate } from 'react-router-dom';
import apiBaseUrl from '../Apiconfig.js';

const AuthContext = createContext()

export default AuthContext;

export const AuthProvider = ({children}) => {
	
	let [authTokens, setAuthTokens] = useState(()=> localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')) : null)
	let [user, setUser] = useState(()=> localStorage.getItem('authTokens') ? jwtDecode(localStorage.getItem('authTokens')) : null)
	let [loading, setLoading] = useState(true)
	
	const navigate = useNavigate();
	
	  let loginUser = async (e )=> {
        e.preventDefault()
        
        let response = await fetch(`${apiBaseUrl}/connect/`, {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json'
    },
    body: JSON.stringify({
        'email': e.target.email.value,
        'password': e.target.password.value
    })
});

        let data = await response.json()

        if(response.status === 200){
            setAuthTokens(data)
            setUser(jwtDecode(data.access))
            console.log(authTokens)
            console.log(user)
            localStorage.setItem('authTokens', JSON.stringify(data))
            navigate('/');
        }else{
            alert('Something went wrong!')
        }
    }
    
    let logoutUser = () => {
        setAuthTokens(null)
        setUser(null)
        localStorage.removeItem('authTokens')
        navigate('/')
    }
    
    let logoutUserupdatetoken = () => {
        setAuthTokens(null)
        setUser(null)
        localStorage.removeItem('authTokens')
    }

	
	 let updateToken = async ()=> {

        let response = await fetch(`${apiBaseUrl}/refresh/`, {
            method:'POST',
            headers:{
                'Content-Type':'application/json'
            },
            body:JSON.stringify({'refresh':authTokens?.refresh})
        })

        let data = await response.json()
        
        if (response.status === 200){
            setAuthTokens(data)
            setUser(jwtDecode(data.access))
            localStorage.setItem('authTokens', JSON.stringify(data))
        }else{
            logoutUserupdatetoken()
        }

        if(loading){
            setLoading(false)
        }
    }
    
    let contextData = {
		user:user,
		authTokens:authTokens,
		loginUser:loginUser,
		logoutUser:logoutUser,
	}
    
    useEffect(()=> {

        if(loading){
            updateToken()
        }

        let fourMinutes = 1000 * 60 * 4

        let interval =  setInterval(()=> {
            if(authTokens){
                updateToken()
            }
        }, fourMinutes)
        return ()=> clearInterval(interval)

    }, [authTokens, loading])

	
	 return(
        <AuthContext.Provider value={contextData} >
            {loading ? null : children}
        </AuthContext.Provider>
    )
       }

import React from 'react';
import { Container, Grid, Typography, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';

import satelliteIcon from './images/satellite.png';
import ASATKIcon from './images/missile.png';
import lawIcon from './images/law.png';
import orgIcon from './images/space-station.png';
import Appbar from '../components/Appbar';


const RequestsPage = () => {
  return (
  <div style={{ position: 'relative', minHeight: '100vh' }}>
      {/* Linear gradient background */}
      <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: -1, background: 'linear-gradient(125deg, #383838, #000000)' }}></div>
      <Appbar/>
    <Container>
    
    
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '50vh' }}
        spacing={2}
      >
         <Grid item xs={8} container alignItems="center" justifyContent="center">
          <Typography variant="h4" gutterBottom style={{ color: '#FFFFFF' ,fontWeight: 'bold'}}>
            Requests
          </Typography>
        </Grid>
        <Grid item xs={8} container alignItems="center" justifyContent="center">
          <Typography variant="body1" style={{ color: '#CCCCCC',textAlign: 'center' }}>
            Help expand the list by adding a record. <br />You can add an organization, a satellite, a weapon and a law.
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <IconButton component={Link} to="organization"  style={{ borderRadius: 0 }}>
            <img src={orgIcon} alt="orga Icon" style={{ width: '40px', height: '40px' }} />
          </IconButton>
            <IconButton component={Link} to="satellite"  style={{ borderRadius: 0 }}>
            <img src={satelliteIcon} alt="satellite Icon" style={{ width: '40px', height: '40px' }} />
          </IconButton>
          
           <IconButton component={Link} to="weapon"  style={{ borderRadius: 0 }}>
            <img src={ASATKIcon} alt="weapon Icon" style={{ width: '40px', height: '40px' }} />
          </IconButton>
           <IconButton component={Link} to="law"  style={{ borderRadius: 0 }}>
            <img src={lawIcon} alt="Law Icon" style={{ width: '40px', height: '40px' }} />
          </IconButton>
        </Grid>
        
      </Grid>
    </Container>
    </div>
  );
};

export default RequestsPage;

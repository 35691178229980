import React, { useState } from 'react';
import axios from 'axios';
import './componentscss/SearchForm.css';

import { DataGrid,} from '@mui/x-data-grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import Spacer from '../components/Spacer';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import apiBaseUrl from '../Apiconfig.js';

function SearchForm() {
  const [searchTerm, setSearchTerm] = useState('');
  const [tag, setTag] = useState('');
  const [results, setResults] = useState([]);
  
  const tags = [
  {
    value: 'Agency',
    label: 'Agency',
  },
  {
    value: 'Education',
    label: 'Education',
  },
  {
    value: 'Equipment',
    label: 'Equipment',
  },
  {
    value: 'Groundstation',
    label: 'Groundstation',
  },
  {
    value: 'IT-AI-Cyber-Data',
    label: 'IT-AI-Cyber-Data',
  },
  {
    value: 'Manufacturer',
    label: 'Manufacturer',
  },
  {
    value: 'Misc',
    label: 'Misc',
  },
  {
    value: 'News',
    label: 'News',
  },
  {
    value: 'Regulatory',
    label: 'Regulatory',
  },
  {
    value: 'Research',
    label: 'Research',
  },
  {
    value: 'Satellite',
    label: 'Satellite',
  },
  {
    value: 'Service',
    label: 'Service',
  },
  {
    value: 'Tourism',
    label: 'Tourism',
  },
];

  const handleSearch = async (e) => {
    e.preventDefault();

    try {
	  const endpoint = `${apiBaseUrl}/orgnamepublic/?orgname=${searchTerm}&tags=${tag}`;
      const response = await axios.get(endpoint);
      setResults(response.data.results);
    } catch (error) {
      console.error(error);
    }
  };



  return (
    <div>
      <form onSubmit={handleSearch}>
      
       {/* <input
          type="text"
          placeholder="Search by organization name"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        
        
        
        <input
          type="text"
          placeholder="Search by tag"
          value={tag}
          onChange={(e) => setTag(e.target.value)}
        />*/}
         <TextField
     label={
    <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'white' ,fontFamily: 'Montserrat, sans-serif', }}>
      Search by organization name
    </Typography>
  }
    variant="standard"
    value={searchTerm}
    onChange={(e) => setSearchTerm(e.target.value)}
    size="small"
   sx={{
        marginRight: '20px',
        width: '100%',
        '& .MuiInput-underline:after': {
          borderBottomColor: 'green', // Custom underline color
        },
      }}
    inputProps={{
      style: { color: 'white' ,borderColor: "white",borderWidth: '2px'}, // Set the color and font weight of the input text to white 
    }}
   
  />
  
  
    
          <TextField
      id="outlined-select-tags"
      select
      label={
        <Typography variant="caption" sx={{ fontWeight: 'bold', color: 'white', fontFamily: 'Montserrat, sans-serif' }}>
          Search by tags
        </Typography>
      }
      size="small"
      variant="standard"
      value={tag}
      sx={{
        width: '100%',
        '& .MuiInput-underline:after': {
          borderBottomColor: 'green', // Custom underline color
        },
        '& .MuiSelect-select': {
          color: 'white',
          textAlign: 'left', // Set the color of the selected text
        },
      }}
      onChange={(e) => setTag(e.target.value)}
      helperText={
        <Typography variant="caption" sx={{ fontWeight: 'bold', color: 'white', fontFamily: 'Montserrat, sans-serif' }}>
          Display all companies using tags
        </Typography>
      }
    >
      {tags.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>

  
       <button type="submit" style={{ backgroundColor: '#125212' }}> <RocketLaunchIcon fontSize="medium" /></button>


      </form>
		<Spacer height="10px" />
		
{results.length > 0 && (
  <DataGrid style={{
    backgroundColor: 'white',
    fontFamily: 'Montserrat, sans-serif',

    /* Other styles */
  }}
  rowHeight={30}
    rows={results}
    columns={[
      { field: 'organisationname', headerName: 'Entity name', flex: 1 },
      { field: 'tags', headerName: 'Tags', flex: 1, valueGetter: (params) => params.row.tags.join(', ') },
      { field: 'description', headerName: 'Description', flex: 1, },
      { field: 'orgtype', headerName: 'Type', flex: 1, },
      { field: 'employees - creator - funding - totalfunding - countrycode - source - contact', headerName: 'Technical, financial, social data.', flex: 1, valueGetter: () => 'Only for connected users.' },
    ]}
    initialState={{
      pagination: {
        paginationModel: { page: 0, pageSize: 5 },
      },
    }}
    pageSizeOptions={[5, 10]}
    checkboxSelection
  />
)}
    </div>
  );
}

export default SearchForm;

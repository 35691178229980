import React, {useContext} from 'react';
import './Appbar.css'; // You can create a new CSS file for the app bar styles
import { Link } from "react-router-dom"; 
import Grid from '@mui/material/Grid';

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import lightsaberIcon from './images/light-saber.png';

import AuthContext from '../utils/AuthContext'

import LoginButton from './LoginButton';
import LogoutButton from './LogoutButton';
import discordIcon from './images/discord.png';




const AppBar = () => {
const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
	
	
	let {user} = useContext(AuthContext)
	
	
	
  return (
    <header className="app-bar">
      {/* Your app bar content goes here */}
       
     
     <Grid container spacing={3} >
  <Grid item xs={3}>
  </Grid>
  
  <Grid item xs={6}>
     <div style={{ textAlign: 'center' }}>
      <nav>
  <Link to="/" className="nav-link">Home</Link>
  <Link to="/Info" className="nav-link">Info</Link>
  <Link to="/Map-demo" className="nav-link">Map</Link>
  <div id="indicator"></div>
</nav>     
</div>
  </Grid>
  
 <Grid item xs={3} style={{ display: 'flex', alignItems: 'center' , marginTop: '5px' }}>
  
   <div className="navbar" style={{ padding: '10px' }}>
      <Button
        className="transparent-button"
        endIcon={<img src={lightsaberIcon} alt="Lightsaber Icon" style={{ width: '30px', height: '30px' }} />}
        aria-controls="dropdown-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{
  color: '#949494',
  borderRadius: '7px',
  fontFamily: 'Montserrat, sans-serif',
  fontWeight: 'bold',
}}
        sx={{
          '&:hover': {
            backgroundColor: '#555',
            borderRadius: '7px',
          },
        }}
      >
        Menu
      </Button>

        <Menu
          id="dropdown-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          PaperProps={{
            style: {
              backgroundColor: '#353535',
              padding: '3px',
              borderRadius: '7px', // Add borderRadius for Menu
            },
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
        
        <MenuItem
            onClick={handleClose}
            sx={{
              '&:hover': {
                backgroundColor: '#555555',
              },
              borderRadius: '7px', // Add borderRadius for MenuItem
            }}
          >
            <Link to="/Advsearch" className="nav-link" style={{ color: '#949494', fontFamily: 'Montserrat, sans-serif' ,textDecoration: 'none'}}>
              Advanced search
            </Link>
          </MenuItem>
          
          <MenuItem
            onClick={handleClose}
            sx={{
              '&:hover': {
                backgroundColor: '#555555',
              },
              borderRadius: '7px', // Add borderRadius for MenuItem
            }}
          >
            <Link to="/Requests" className="nav-link" style={{ color: '#949494', fontFamily: 'Montserrat, sans-serif' ,textDecoration: 'none'}}>
              Contribute
            </Link>
          </MenuItem>
          
          <MenuItem
            onClick={handleClose}
            sx={{
              '&:hover': {
                backgroundColor: '#555555',
              },
              borderRadius: '7px', // Add borderRadius for MenuItem
            }}
          >
            <Link to="/Spaceweapons" className="nav-link" style={{ color: '#949494', fontFamily: 'Montserrat, sans-serif' ,textDecoration: 'none'}}>
              Space weapons
            </Link>
          </MenuItem>

          {/* Add more links as needed */}
        </Menu>

    </div>
    <div className="loginbutton" style={{ padding: '10px' }}>
    {!user && <LoginButton/>}
    {user && <LogoutButton/>}
     </div>
      <div className="discordbutton" style={{ padding: '10px' }}>
       <a href="https://discord.gg/HGj6xPTAyr" target="_blank" rel="noopener noreferrer">
      <img src={discordIcon} alt="orga Icon" style={{ width: '30px', height: '30px' }} />
    </a>
     </div>
    
    
  
  </Grid>
</Grid>
     


       
    </header>
  );
};

export default AppBar;

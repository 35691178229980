import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Alert from '@mui/material/Alert';
import './App.css';
import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import HelpIcon from '@mui/icons-material/Help';
import Tooltip from '@mui/material/Tooltip';
import Carousel from '../components/Carousel';
import Button from '@mui/material/Button';
import FileCopyIcon from '@mui/icons-material/FileCopy';

//my components
import Appbar from '../components/Appbar';
import Spacer from '../components/Spacer';
import Container from '@mui/material/Container';
import MediaCardorg from '../components/cards/Companynumber';
import MediaCardsatellites from '../components/cards/Satellitesnumber';
import MediaCardweapon from '../components/cards/Weaponnumber';
import MediaCardip from '../components/cards/Ipnumber';
import MediaCardfinancial from '../components/cards/Financialnumber';
import MediaCardlaws from '../components/cards/Lawsnumber';
import GlobeComponent from '../components/GlobeComponent';
import SearchForm from '../components/Search';
import Footer from '../components/Footer';

import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';








const Index = () => {
	const textToCopy = "https://api.recon.space/myapi/";
	 const [isCopied, setIsCopied] = useState(false);

  const handleCopyText = () => {
    const textArea = document.createElement('textarea');
    textArea.value = textToCopy;

    document.body.appendChild(textArea);

    textArea.select();
    document.execCommand('copy');

    document.body.removeChild(textArea);

    setIsCopied(true);

    // Reset the "Copied" indicator after a short delay
    setTimeout(() => {
      setIsCopied(false);
    }, 2000); // Adjust the delay as needed
  };
	
	
	const buttonStyle = {
    backgroundColor: 'transparent', // Gray background color
    color: '#fff', // White text color
    border: 'none',
    borderRadius: '15px',
    textTransform: 'lowercase',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.7)',
    
  };
  
  return (
     <div style={{ position: 'relative', minHeight: '100vh' }}>
      {/* Linear gradient background */}
      <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: -1, background: 'linear-gradient(125deg, #383838, #000000)' }}></div>
    <Appbar/>
      <Spacer height="40px" />

      <Container sx={{
           
          borderRadius: '6px',
          border: 'none',
          maxWidth: '100%',
          boxShadow: '0px 0px 100px 7px rgba(0, 0, 0, 0.5)',
        }}
        maxWidth={false}
      >
      <Spacer height="20px" />
      <Grid
  container
  spacing={0}
  direction="column"
  alignItems="center"
  
>

  <Grid item xs={3}>
  <Box
      sx={{
        display: 'flex',
        textAlign: 'justify',
        color: 'white',
        fontFamily: 'Montserrat',
        marginBottom: '10px',
        backgroundColor: 'transparent', // Adjust the color to a darker shade
        borderRadius: '4px',
      }}
    >
    <Typography variant="body1" sx={{color: 'white', textAlign: 'center',fontWeight: 'bold',fontFamily: 'Montserrat, sans-serif',}}>
        This, is the data currently in database. One record contains plenty of attributes, isn't it huge?!
      </Typography>
      
      <Tooltip title= <div> Recon[.]Space works with partners to maintain the highest quality space industry information. Data goes through many pipelines to be sorted, analyzed, cleared, enriched and prepared before arriving in our database. More information about our  
          <a href="https://recon.space/Info" target="_blank" rel="noopener noreferrer">
            &nbsp;data.
          </a>
          
        </div> arrow> 
      <IconButton color="primary">
        <HelpIcon />
      </IconButton>
    </Tooltip>
     </Box>
  </Grid>
</Grid>
       
      <Spacer height="20px" />
        <Grid container spacing={2}>
        
          {/* Repeat this grid item for each MediaCard */}
          <Grid item xs={2}>
            <MediaCardorg />
          </Grid>
          <Grid item xs={2}>
            <MediaCardsatellites />
          </Grid>
          <Grid item xs={2}>
            <MediaCardweapon />
          </Grid>
          <Grid item xs={2}>
            <MediaCardip />
          </Grid>
          <Grid item xs={2}>
            <MediaCardlaws />
          </Grid>
          <Grid item xs={2}>
            <MediaCardfinancial />
          </Grid>
          
        </Grid>
      </Container>

      <Spacer height="40px" />

      <Container
        sx={{
          background:
            'black',
          borderRadius: '6px',
          border: 'none',
          maxWidth: '100%',
          boxShadow: '0px 0px 100px 7px rgba(0, 0, 0, 0.5)',
        }}
        maxWidth={false}
      >
<Spacer height="20px" />
 <Grid
  container
  spacing={0}
  direction="column"
  alignItems="center"
  
>
  <Grid item xs={3}>
  <Box
      sx={{
        display: 'flex',
        textAlign: 'justify',
        color: 'white',
        fontFamily: 'Montserrat',
        marginBottom: '10px',
        backgroundColor: 'transparent', // Adjust the color to a darker shade
        borderRadius: '4px',
      }}
    >
   <Typography variant="body1" sx={{color: 'white', textAlign: 'center',fontWeight: 'bold',fontFamily: 'Montserrat, sans-serif',}}>
        This, is what we can do for you, based on our data, and expertise.
      </Typography>
      
      <Tooltip title= <div> Recon[.]Space offers services. Bellow you can see a Globe where satellites and GPS locations are set up, this is using our engineering expertise and our data. All GPS locations can be collected using the Recon[.]Space API. Get more information about our 
          <a href="https://recon.space/Info" target="_blank" rel="noopener noreferrer">
            &nbsp;expertise.
          </a>
          
        </div> arrow> 
      <IconButton color="primary">
        <HelpIcon />
      </IconButton>
    </Tooltip>
     </Box>
  </Grid>
</Grid>
       
      <Spacer height="20px" />

<Grid container spacing={2}>
  <Grid
    item
    xs={5}
    style={{
      position: 'relative',
      display: 'flex',
      justifyContent: 'center', // Horizontally center
      alignItems: 'center', // Vertically center
    }}
  >
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundImage: `url(${require('./images/test.png')})`,
        backgroundSize: 'cover', // Adjust as needed
        backgroundPosition: 'center', // Adjust as needed
        opacity: 0.25,
      }}
    />
    <div style={{ padding: '20px' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          textAlign: 'center',
        }}
      >
        <Carousel/>
      </div>
    </div>
  </Grid>


          <Grid item xs={3} style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
            <img src={require('./images/testapi.png')} alt="Test API" />
          </Grid>
          
          <Grid item xs={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        <GlobeComponent />
        <div className="paragraph1" style={{ marginTop: '-50px' }}>
         Few companies GPS loc' directly from the API.
        </div>
      </Grid>
        </Grid>
      </Container>
      
      <Spacer height="40px" />
      
      
      <Container sx={{
           
          borderRadius: '6px',
          border: 'none',
          maxWidth: '100%',
          
          boxShadow: '0px 0px 100px 7px rgba(0, 0, 0, 0.5)',
        }}
        maxWidth={false}
      >
      <Spacer height="20px" />
      
      <Grid
  container
  spacing={0}
  direction="column"
  alignItems="center"
  
>

  <Grid item xs={3}>
  <Box
      display="flex"
      alignItems="center"
      
    >
    <Typography variant="body1" sx={{color: 'white', textAlign: 'center',fontWeight: 'bold',fontFamily: 'Montserrat, sans-serif',}}>
        This, is the Recon[.]Space API, it allows you to get access to the data stored in database.
      </Typography>
      
      <Tooltip title= <div> The API is composed of what we call "endpoints", endpoints are available via https://api.recon.space. They allow you to retrieve the data in the database. We make it easy for you, Recon[.]Space added multiple filters, and visualizations to help you. However, you may need the  
          <a href="https://recon.space/Info" target="_blank" rel="noopener noreferrer">
            &nbsp;Documentation.
          </a>
          
        </div> arrow> 
      <IconButton color="primary">
        <HelpIcon />
      </IconButton>
    </Tooltip>
     </Box>
  </Grid>
</Grid>

 <Grid
  container
  spacing={0}
  direction="column"
  alignItems="center"
  
>

  <Grid item xs={12}>
  
 <Box
      display="flex"
      alignItems="center"
      sx={{
        '& .MuiTypography-root': {
          color: '#50a650',
          fontWeight: 'bold',
          fontFamily: 'Montserrat, sans-serif',
        },
        '& .MuiIconButton-root': {
          color: 'white',
        },
      }}
    >
    <IconButton onClick={handleCopyText}>
        <FileCopyIcon />
      </IconButton>
      <Typography>{textToCopy}</Typography>
      
    </Box>
     
  </Grid>
</Grid>

   <Box display="flex" alignItems="center">
      <Typography variant="h6" style={{color: 'white', fontWeight: 'bold',  }}>
        Endpoints accessible for everyone.
      </Typography>
      <div
        style={{
          flex: 1,
          borderTop: '2px solid #fff', // White bold line on the right
          marginLeft: '10px', // Adjust the spacing between the text and the line
        }}
      ></div>
    </Box>
  <Grid container spacing={2}>
  
        
          {/* Repeat this grid item for each MediaCard */}
          
          <Grid item xs={2}>
          <Button style={buttonStyle} onClick={() => {
          window.location.href = 'https://api.recon.space/myapi/connect/';
        }}>connect/</Button>
          </Grid>
          <Grid item xs={2}>
          <Button style={buttonStyle} onClick={() => {
          window.location.href = 'https://api.recon.space/myapi/refresh/';
        }}>refresh/</Button>
          </Grid>
          <Grid item xs={2}>
          <Button style={buttonStyle}onClick={() => {
          window.location.href = 'https://api.recon.space/myapi/records/';
        }} >records/</Button>
          </Grid>
           <Grid item xs={2}>
          <Button style={buttonStyle} onClick={() => {
          window.location.href = 'https://recon.space/';
        }}>orgnamepublic/</Button>
          </Grid>
           <Grid item xs={2}>
          <Button style={buttonStyle} onClick={() => {
          window.location.href = 'https://recon.space/Map-demo';
        }}>orgnamegpspublic/</Button>
          </Grid>
           <Grid item xs={2}>
          <Button style={buttonStyle} onClick={() => {
          window.location.href = 'https://recon.space/Spaceweapons';
        }}>weaponspublic/</Button>
          </Grid>
          <Grid item xs={2}>
          <Button style={buttonStyle} onClick={() => {
          window.location.href = 'https://api.recon.space/myapi/tag/';
        }}>tag/</Button>
          </Grid>
          
        </Grid>
       
      <Spacer height="20px" />
      <Box display="flex" alignItems="center">
      <Typography variant="h6" style={{color: 'white', fontWeight: 'bold',  }}>
        Endpoints accessible for connected users.
      </Typography>
      <div
        style={{
          flex: 1,
          borderTop: '2px solid #fff', // White bold line on the right
          marginLeft: '10px', // Adjust the spacing between the text and the line
        }}
      ></div>
    </Box>
      <Grid container spacing={2}>
  
        
          {/* Repeat this grid item for each MediaCard */}
          <Grid item xs={2}>
          <Button style={buttonStyle} onClick={() => {
          window.location.href = 'https://api.recon.space/myapi/myaccount/';
        }}>myaccount/</Button>
          </Grid>
          <Grid item xs={2}>
          <Button style={buttonStyle} onClick={() => {
          window.location.href = 'https://api.recon.space/myapi/orgname/';
        }}>orgname/</Button>
          </Grid>
          <Grid item xs={2}>
          <Button style={buttonStyle} onClick={() => {
          window.location.href = 'https://api.recon.space/myapi/orgnamegps/';
        }}>orgnamegps/</Button>
          </Grid>
          <Grid item xs={2}>
          <Button style={buttonStyle} onClick={() => {
          window.location.href = 'https://api.recon.space/myapi/domain/';
        }}>domain/</Button>
          </Grid>
          <Grid item xs={2}>
          <Button style={buttonStyle} onClick={() => {
          window.location.href = 'https://api.recon.space/myapi/subdomain/';
        }}>subdomain/</Button>
          </Grid>
          <Grid item xs={2}>
          <Button style={buttonStyle} onClick={() => {
          window.location.href = 'https://api.recon.space/myapi/ip/<id>';
        }}>ip/</Button>
          </Grid>
          <Grid item xs={2}>
          <Button style={buttonStyle} onClick={() => {
          window.location.href = 'https://api.recon.space/myapi/satellite/<id>';
        }}>satellite/</Button>
          </Grid>
          <Grid item xs={2}>
          <Button style={buttonStyle} onClick={() => {
          window.location.href = 'https://api.recon.space/myapi/taglaws/';
        }}>taglaws/</Button>
          </Grid>
          <Grid item xs={2}>
          <Button style={buttonStyle} onClick={() => {
          window.location.href = 'https://api.recon.space/myapi/weapons/';
        }}>weapons/</Button>
          </Grid>
          <Grid item xs={2}>
          <Button style={buttonStyle} onClick={() => {
          window.location.href = 'https://api.recon.space/myapi/financial/';
        }}>financial/</Button>
          </Grid>
          
          
        </Grid>
      </Container>
      
      
      <Spacer height="40px" />
      
      
      <Container
        sx={{
          
          borderRadius: '6px',
          border: 'none',
          maxWidth: '100%',
          boxShadow: '0px 0px 100px 7px rgba(0, 0, 0, 0.5)',
        }}
        maxWidth={false}
      >
      
       <Grid
  container
  spacing={0}
  direction="column"
  alignItems="center"
  
>

  <Grid item xs={3}>
  <Box
      display="flex"
      alignItems="center"
      
    >
    <Typography variant="body1" sx={{color: 'white', textAlign: 'center',fontWeight: 'bold',fontFamily: 'Montserrat, sans-serif',}}>
        This, is one of the Recon[.]Space API demo. You can test the endpoint orgname/.
      </Typography>
      
      <Tooltip title= <div> Recon[.]Space provides specific features to help you analyze the data. The endpoint orgname/ is built in a way to easy retrieve companies by tags. The search filter can help you find the data of the company of your choice as well.
          
          
        </div> arrow> 
      <IconButton color="primary">
        <HelpIcon />
      </IconButton>
    </Tooltip>
     </Box>
  </Grid>
</Grid>
      <Grid container spacing={2}>
  <Grid item xs={4}>
    <Typography variant="h6" sx={{ color: 'white',textAlign: 'left', fontFamily: 'Montserrat', fontWeight: 'bold', fontStyle: 'italic', letterSpacing: '2px' }}>
        <ManageSearchIcon sx={{ fontSize: '1.2em', marginRight: '8px', verticalAlign: 'middle' }} /> Demo | API
      </Typography>
  </Grid>
  <Grid item xs={4}>
  
  </Grid>
  <Grid item xs={4}>
  
  </Grid>
    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', alignSelf: 'flex-start'}}>
 <Alert severity="success">
    Demo of the "/orgname" API endpoint. The results are limited to <strong>a sample of rows</strong> (less than 33% of the database); register an account for more.
    <br /><br />
    Another demo is available here: https://recon.space/Map-demo
    <br /><br />
    Click on the  <RocketLaunchIcon fontSize="small" />to fetch all the results in one shot!
</Alert>

  </Grid>
  <Grid item xs={12}>
    <SearchForm/>
  </Grid>

</Grid>
      </Container>
      
      <Spacer height="40px" />
      <Footer />
      <Spacer height="3px" />
    </div>
  );
};

export default Index;

import React, { useState,useContext } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import LoginIcon from '@mui/icons-material/Login';
import AuthContext from '../utils/AuthContext';
import { Typography} from '@mui/material';
import RocketIcon from '@mui/icons-material/Rocket';

const LoginButton = () => {
  const [open, setOpen] = useState(false);


  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  
  
  let {loginUser} = useContext(AuthContext)

  return (
    <div>
      <Button
        variant="outlined"
        onClick={handleOpen}
        startIcon={<LoginIcon style={{ color: 'green' }} />} // Set icon color
        style={{
          color: 'green', // Set text color
          borderColor: 'green', // Set border color
        }}
      >
        Login
      </Button>

      <Dialog open={open} onClose={handleClose}   PaperProps={{ style: { backgroundColor: '#333333' } }} >
        <DialogTitle style={{ fontWeight: 'bold', color: 'white', fontFamily: 'Montserrat' }}>
          Login
        </DialogTitle>
        <DialogContent>
          <form onSubmit={loginUser}>
      
      
      <TextField
        type="text"
        name="email"
        label={
    <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'white' ,fontFamily: 'Montserrat, sans-serif', }}>
      Enter email
    </Typography>
  }
        variant="standard"
        margin="normal"
        fullWidth
        sx={{
        width: '100%',
        maxWidth: '400px', // Adjust the max width as needed
        '& .MuiInput-underline:after': {
          borderBottomColor: 'green', // Custom underline color
        },
      }}
    inputProps={{
      style: { color: 'white' ,borderColor: "white",borderWidth: '2px'}, // Set the color and font weight of the input text to white 
    }}
      />
      
      
       <TextField
        type="password"
        name="password"
        label={
    <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'white' ,fontFamily: 'Montserrat, sans-serif', }}>
      Enter Password
    </Typography>
  }
        variant="standard"
        margin="normal"
        fullWidth
        sx={{
        width: '100%',
        maxWidth: '400px', // Adjust the max width as needed
        '& .MuiInput-underline:after': {
          borderBottomColor: 'green', // Custom underline color
        },
      }}
    inputProps={{
      style: { color: 'white' ,borderColor: "white",borderWidth: '2px'}, // Set the color and font weight of the input text to white 
    }}
      />
      
      
      
      <Button
   type="submit"
        value="Submit"
        variant="outlined"
        margin="normal"
        fullWidth
  startIcon={<RocketIcon />}
  style={{ backgroundColor: 'green', color: 'white' }}
>
  Submit
</Button>

       <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
       <Typography variant="body1" sx={{  color: 'white' ,fontFamily: 'Montserrat, sans-serif', }}>
      No account yet?
    </Typography>
      <a href={`./register`} target="_blank" rel="noopener noreferrer">
        Create your account.
      </a>
    </div>
      
    </form>
        </DialogContent>
        <DialogActions>
           <Button onClick={handleClose} style={{ color: 'green' }}>
            Close
          </Button>
         
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default LoginButton;

import React from 'react';
import { BrowserRouter as Router,Routes, Route } from 'react-router-dom';
import Home from './pages/index';
import Pricing from './pages/InfoPage.js';
import API from './pages/Map-demo.js';
import Advsearch from './pages/Advsearch.js';
import Spaceweapons from './pages/Spaceweapons.js';
import Requests from './pages/Requests.js';
import Requestsorga from './pages/Requests-organization.js';
import Requestssatellite from './pages/Requests-satellite.js';
import Requestsweapon from './pages/Requests-weapon.js';
import Requestslaw from './pages/Requests-law.js';
import Pubkey from './components/Pubkey.js';
import Register from './pages/Register.js';


import PrivateRoute from './utils/PrivateRoute.js';
import { AuthProvider} from './utils/AuthContext.js';

import './App.css';






function App() {
  return (
    <div className="App">
      <Router>
      <AuthProvider>
          <Routes>
                <Route exact path='/' element={< Home />}></Route>
                <Route exact path='/Info' element={< Pricing />}></Route>
                <Route exact path='/Map-demo' element={< API />}></Route>
                <Route exact path='/Spaceweapons' element={< Spaceweapons />}></Route>
                <Route exact path='/Requests' element={< Requests />}></Route>
                <Route exact path='/Requests/organization' element={< Requestsorga />}></Route>
                <Route exact path='/Requests/satellite' element={< Requestssatellite />}></Route>
                <Route exact path='/Requests/weapon' element={< Requestsweapon />}></Route>
                <Route exact path='/Requests/law' element={< Requestslaw />}></Route>
                <Route exact path='/Pubkey' element={< Pubkey />}></Route>
                <Route exact path='/Register' element={< Register />}></Route>
                
                <Route
            path="/Advsearch"
            element={<PrivateRoute element={<Advsearch />} />}
          />
          
                 
        </Routes>
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;

  

import React, { useState } from 'react';
import { Container, Typography,Alert } from '@mui/material';
import Appbar from '../components/Appbar';
import { Button, TextField } from '@mui/material';
import RocketIcon from '@mui/icons-material/Rocket';
import apiBaseUrl from '../Apiconfig.js';
import ReCAPTCHA from 'react-google-recaptcha';
import Footer from '../components/Footer';
import Spacer from '../components/Spacer';

const Register = () => {
	 const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  
  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };
  
  
  
  

  const [registrationStatus, setRegistrationStatus] = useState('pending'); // 'pending', 'success', 'error'

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  
 
  const handleSubmit = async (event) => {
	  if (!recaptchaValue) {
      alert('Please complete the reCAPTCHA verification.');
      return;
    }
	  
    event.preventDefault();

    // Send a POST request to the "/register" endpoint with the form data
    try {
	  const endpoint = `${apiBaseUrl}/register/`;
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        // Handle success
        console.log('Registration successful');
        setRegistrationStatus('success');
      } else {
        // Handle error
        console.error('Registration failed');
        setRegistrationStatus('error');
      }
    } catch (error) {
      console.error('Error during registration:', error);
      setRegistrationStatus('error');
    }
  };
  return (
  <div style={{ position: 'relative', minHeight: '100vh' }}>
      {/* Linear gradient background */}
      <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: -1, background: 'linear-gradient(125deg, #383838, #000000)' }}></div>
      <Appbar/>
     <Container>
        {registrationStatus === 'pending' && (
          <form onSubmit={handleSubmit} style={{ width: '100%', marginTop: 20 }}>
            <TextField
              type="text"
              name="email"
              label={
                <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'white', fontFamily: 'Montserrat, sans-serif' }}>
                  Enter email
                </Typography>
              }
              variant="standard"
              margin="normal"
              fullWidth
              value={formData.email}
              onChange={handleChange}
              sx={{
                width: '100%',
                '& .MuiInput-underline:after': {
                  borderBottomColor: 'green',
                },
              }}
              inputProps={{
                style: { color: 'white', borderColor: 'white', borderWidth: '2px' },
              }}
            />

            <TextField
              type="password"
              name="password"
              label={
                <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'white', fontFamily: 'Montserrat, sans-serif' }}>
                  Enter Password
                </Typography>
              }
              variant="standard"
              margin="normal"
              fullWidth
              value={formData.password}
              onChange={handleChange}
              sx={{
                width: '100%',
                '& .MuiInput-underline:after': {
                  borderBottomColor: 'green',
                },
              }}
              inputProps={{
                style: { color: 'white', borderColor: 'white', borderWidth: '2px' },
              }}
            />
            <ReCAPTCHA
            sitekey="6Lcqq3soAAAAAIX0Y4Lbfm9x9lgQMKajthY0EqSy"
            onChange={handleRecaptchaChange}
          />

            <Button
              type="submit"
              value="Submit"
              variant="outlined"
              margin="normal"
              fullWidth
              startIcon={<RocketIcon />}
              style={{ backgroundColor: 'green', color: 'white' }}
            >
              Submit
            </Button>
            
             

          {/* Submit Button */}
           
          </form>
        )}

        {registrationStatus === 'success' && (
          <div>
            {/* Display a success message or custom content */}
             <Alert severity="success" style={{ marginTop: 20 }}>
            Registration successful! You can now log in.
          </Alert>
          </div>
        )}

        {registrationStatus === 'error' && (
          <div>
            {/* Display an error message or custom content */}
             <Alert severity="error" style={{ marginTop: 20 }}>
            Registration failed. Please contact the support via the discord if the error persists.
          </Alert>
          </div>
        )}
      </Container>
      
            <Spacer height="40px" />
      <Footer />
      <Spacer height="3px" />
    </div>
  );
};

export default Register;

import React, { useState } from 'react';
import { Grid, TextField, Typography } from '@mui/material';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import orgIcon from './images/space-station.png';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import apiBaseUrl from '../Apiconfig.js';

const useStyles = {
  formContainer: {
    padding: '20px',
    textAlign: 'center',
    borderRadius: '8px', // Optional: Add border radius for rounded corners
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.5)', 
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  textField: {
    margin: '10px 0',
    width: '100%',
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green', // Custom underline color
    },
  },
  button: {
    marginTop: '20px',
  },
};

const RequestsOrganization = () => {
  const [formData, setFormData] = useState({
    organisationname: '',
    countrycode: '',
    orgtype: '',
    founded: '',
    fundingtype: '',
    totalfunding: '',
    employees: '',
    creator: '',
    description: '',
    gps: '',
    source: '',
  });

  const [recaptchaValue, setRecaptchaValue] = useState(null);

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if reCAPTCHA is ticked
    if (!recaptchaValue) {
      alert('Please complete the reCAPTCHA verification.');
      return;
    }

    try {
      // Remove any formatting characters (e.g., commas) from the funding value
      const formattedFunding = formData.totalfunding.replace(/,/g, '');

      // Parse the value to a floating-point number
      const fundingValue = parseFloat(formattedFunding);

      // Check if the parsed value is a valid number
      if (isNaN(fundingValue)) {
        alert('Invalid funding value. Please enter a valid number.');
        return; // Don't proceed with the request if the value is not a valid number
      }

      // Update the formData with the parsed value
      setFormData({
        ...formData,
        totalfunding: fundingValue,
      });

      console.log('Submitting data:', formData);
	  const endpoint = `${apiBaseUrl}/organization-requests/`;
      const response = await axios.post(endpoint, formData);

      console.log('Response from server:', response);

      if (response.status === 201) {
        alert('Organization request submitted successfully!');
        // Handle success, e.g., redirect or show a success message
      } else {
        alert('Failed to submit organization request. Please try again.');
        console.error('Server response:', response.data);
        // Handle error, e.g., show an error message
      }
    } catch (error) {
      alert('Error submitting organization request. Please try again.');
      console.error('Error submitting organization request:', error);
      console.log('Server response:', error.response);
    }
  };

  return (
  
   
    <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
      <Grid item xs={10} sm={8} md={6} style={useStyles.formContainer}>
        <Typography variant="h6" sx={{ color: 'white', fontFamily: 'Montserrat', fontWeight: 'bold', fontStyle: 'italic', letterSpacing: '2px' }}>
        <img src={orgIcon} alt="orga Icon" style={{ width: '40px', height: '40px' }} /> Submit an organization
      </Typography>
        <form style={useStyles.form} onSubmit={handleSubmit}>
        
        
   <TextField
  className={useStyles.customTextField}
  label={
    <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'white', fontFamily: 'Montserrat, sans-serif' }}>
      Organization Name
    </Typography>
  }
  variant="standard"
  size="small"
  name="organisationname"
  value={formData.organisationname}
  onChange={handleInputChange}
  required
  sx={{
    width: '100%',
   maxWidth: '600px',
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiInputLabel-root': {
      color: 'white',
    },
    '& .MuiInputLabel-asterisk': {
      display: 'none', // Hide the asterisk
    },
  }}
  inputProps={{
    style: { color: 'white', borderColor: '#9d9d9d', borderWidth: '2px' },
  }}
/>

          <TextField
  className={useStyles.customTextField}
  label={
    <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'white', fontFamily: 'Montserrat, sans-serif' }}>
      Country Code
    </Typography>
  }
  variant="standard"
  size="small"
  name="countrycode"
  value={formData.countrycode}
  onChange={handleInputChange}
  required
  sx={{
    width: '100%',
   maxWidth: '600px',
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiInputLabel-root': {
      color: 'white',
    },
    '& .MuiInputLabel-asterisk': {
      display: 'none', // Hide the asterisk
    },
  }}
  inputProps={{
    style: { color: 'white', borderColor: '#9d9d9d', borderWidth: '2px' },
  }}
/>

          <TextField
  className={useStyles.customTextField}
  label={
    <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'white', fontFamily: 'Montserrat, sans-serif' }}>
      Organization Type
    </Typography>
  }
  variant="standard"
  size="small"
  name="orgtype"
  value={formData.orgtype}
  onChange={handleInputChange}
  required
  sx={{
    width: '100%',
    maxWidth: '600px',
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiInputLabel-root': {
      color: 'white',
    },
    '& .MuiInputLabel-asterisk': {
      display: 'none', // Hide the asterisk
    },
  }}
  inputProps={{
    style: { color: 'white', borderColor: '#9d9d9d', borderWidth: '2px' },
  }}
/>

          <TextField
  className={useStyles.customTextField}
  label={
    <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'white', fontFamily: 'Montserrat, sans-serif' }}>
      Creation Date
    </Typography>
  }
  variant="standard"
  size="small"
  type="date"
  name="founded"
  value={formData.founded}
  onChange={handleInputChange}
  InputLabelProps={{
    shrink: true,
  }}
  required
  sx={{
    width: '100%',
    maxWidth: '600px',
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiInputLabel-root': {
      color: 'white',
    },
    '& .MuiInputLabel-asterisk': {
      display: 'none', // Hide the asterisk
    },
  }}
  inputProps={{
    style: { color: 'white', borderColor: '#9d9d9d', borderWidth: '2px' },
  }}
/>

          <TextField
  className={useStyles.customTextField}
  label={
    <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'white', fontFamily: 'Montserrat, sans-serif' }}>
      Funding Type
    </Typography>
  }
  variant="standard"
  size="small"
  name="fundingtype"
  value={formData.fundingtype}
  onChange={handleInputChange}
  required
  sx={{
    width: '100%',
    maxWidth: '600px',
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiInputLabel-root': {
      color: 'white',
    },
    '& .MuiInputLabel-asterisk': {
      display: 'none', // Hide the asterisk
    },
  }}
  inputProps={{
    style: { color: 'white', borderColor: '#9d9d9d', borderWidth: '2px' },
  }}
/>
          <TextField
  className={useStyles.customTextField}
  label={
    <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'white', fontFamily: 'Montserrat, sans-serif' }}>
      Funding in Dollar
    </Typography>
  }
  variant="standard"
  size="small"
  type="text"
  name="totalfunding"
  value={formData.totalfunding}
  onChange={handleInputChange}
  required
  sx={{
    width: '100%',
   maxWidth: '600px',
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiInputLabel-root': {
      color: 'white',
    },
    '& .MuiInputLabel-asterisk': {
      display: 'none', // Hide the asterisk
    },
  }}
  inputProps={{
    style: { color: 'white', borderColor: '#9d9d9d', borderWidth: '2px' },
  }}
/>


          <TextField
  className={useStyles.customTextField}
  label={
    <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'white', fontFamily: 'Montserrat, sans-serif' }}>
      Employees
    </Typography>
  }
  variant="standard"
  size="small"
  name="employees"
  value={formData.employees}
  onChange={handleInputChange}
  required
  sx={{
    width: '100%',
    maxWidth: '600px',
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiInputLabel-root': {
      color: 'white',
    },
    '& .MuiInputLabel-asterisk': {
      display: 'none', // Hide the asterisk
    },
  }}
  inputProps={{
    style: { color: 'white', borderColor: '#9d9d9d', borderWidth: '2px' },
  }}
/>

         <TextField
  className={useStyles.customTextField}
  label={
    <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'white', fontFamily: 'Montserrat, sans-serif' }}>
      Creator
    </Typography>
  }
  variant="standard"
  size="small"
  name="creator"
  value={formData.creator}
  onChange={handleInputChange}
  required
  sx={{
    width: '100%',
    maxWidth: '600px',
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiInputLabel-root': {
      color: 'white',
    },
    '& .MuiInputLabel-asterisk': {
      display: 'none', // Hide the asterisk
    },
  }}
  inputProps={{
    style: { color: 'white', borderColor: '#9d9d9d', borderWidth: '2px' },
  }}
/>


    <TextField
  className={useStyles.customTextField}
  label={
    <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'white', fontFamily: 'Montserrat, sans-serif' }}>
      Description
    </Typography>
  }
  variant="standard"
  size="small"
  multiline
  rows={4}
  name="description"
  value={formData.description}
  onChange={handleInputChange}
  required
  sx={{
    width: '100%',
    maxWidth: '600px',
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiInputLabel-root': {
      color: 'white',
    },
    '& .MuiInputLabel-asterisk': {
      display: 'none', // Hide the asterisk
    },
  }}
  inputProps={{
    style: { color: 'white', borderColor: '#9d9d9d', borderWidth: '2px' },
  }}
/>



          <TextField
  className={useStyles.customTextField}
  label={
    <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'white', fontFamily: 'Montserrat, sans-serif' }}>
      GPS Location
    </Typography>
  }
  variant="standard"
  size="small"
  name="gps"
  value={formData.gps}
  onChange={handleInputChange}
  required
  sx={{
    width: '100%',
    maxWidth: '600px',
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiInputLabel-root': {
      color: 'white',
    },
    '& .MuiInputLabel-asterisk': {
      display: 'none', // Hide the asterisk
    },
  }}
  inputProps={{
    style: { color: 'white', borderColor: '#9d9d9d', borderWidth: '2px' },
  }}
/>


          <TextField
  className={useStyles.customTextField}
  label={
    <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'white', fontFamily: 'Montserrat, sans-serif' }}>
      Source
    </Typography>
  }
  variant="standard"
  size="small"
  name="source"
  value={formData.source}
  onChange={handleInputChange}
  required
  sx={{
    width: '100%',
    maxWidth: '600px',
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiInputLabel-root': {
      color: 'white',
    },
    '& .MuiInputLabel-asterisk': {
      display: 'none', // Hide the asterisk
    },
  }}
  inputProps={{
    style: { color: 'white', borderColor: '#9d9d9d', borderWidth: '2px' },
  }}
/>


          {/* reCAPTCHA */}
          <ReCAPTCHA
            sitekey="6Lcqq3soAAAAAIX0Y4Lbfm9x9lgQMKajthY0EqSy"
            onChange={handleRecaptchaChange}
          />

          {/* Submit Button */}
           <button
  type="submit"
  style={{
    backgroundColor: '#125212',
    fontSize: '1.5rem', // Adjust the font size to make the button bigger
    padding: '10px 20px', // Adjust the padding for more size
    borderRadius: '8px', // Add border-radius for a rounded appearance
    color: 'white', // Text color
    border: 'none', // Remove default button border
    cursor: 'pointer', // Show a pointer cursor on hover
  }}
>
  <RocketLaunchIcon fontSize="large" /> Submit
</button>
        </form>
      </Grid>
    </Grid>
  );
};

export default RequestsOrganization;


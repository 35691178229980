// Pubkey.js
import React from 'react';

const HelloWorld = () => {
  return (
    <div>
    <pre>
      -----BEGIN PGP PUBLIC KEY BLOCK-----{'\n'}
{'\n'}
mQINBGVpmmwBEADG/pyDWsKlt9LqOsBYfmpVTy3SoJVccw2mQH6+dy2qqOZTm0Sf{'\n'}
OPvpxh5T84Gr2eCGDCVpndIfzFiA49XvFXLSpt8znxTAkSGpNk3IiDt0woe9F0yq{'\n'}
FMZJ4ry1KBElNtynyBfO7OaaDm3vZbHm+i09l5s89eClw2AoKDlp60cC9MnCwAlr{'\n'}
vg2eVWqg2FYviciAUkXYCaVKeh/HNyX1B5G4eL5xnX7Rfi6rDCSnJnZJuZ+NgWz0{'\n'}
XvUgqJpawvFeBm7x/xBXGoPXY+9AV8gMlrrqrpOAIW4JCICNKqwxy17knqcrAJ0c{'\n'}
nmkMZGHvmmQWN6YYqmPQ9L++QiJFGYCG2av4lQ+7Eq6SwDAicNDGvjGrWm6uWsG0{'\n'}
lSPmyM0ETkTfUxW3AefsYeuyQeaa70hVbHLikStzhX0MNrx2KxAnCWsED8ecGfmL{'\n'}
J81yP7UjlKptPYBSwNa4whpZFCuTqMSkJrFwFsCiws8u9rNK8CFfPOqlo2kwXNle{'\n'}
CrmdoIJGsO6FUKDybBYXaROAjgKaN5qahKFBUm/Vwwy/GeOs1gygw2F/BUKdgk8z{'\n'}
BIBqFK4DNwbpLGAORIfD/LnxOc4gyZYAPhicUfByiIDEAIA2PixarsVMZpug/0Qs{'\n'}
871MLRnp5TackdZotasYtSQHxUfTokiLN6TBlrA5Coi/dOvcjdf8byRd0QARAQAB{'\n'}
tB1TdXBwb3J0IDxzdXBwb3J0QHJlY29uLnNwYWNlPokCVAQTAQoAPhYhBKM93S0j{'\n'}
Ly1NX0LBv8tuHfcl7tSVBQJlaZpsAhsDBQkDwmcABQsJCAcCBhUKCQgLAgQWAgMB{'\n'}
Ah4BAheAAAoJEMtuHfcl7tSVO1kQAMZdNHwhYvaOhbkc0lBfokDtoQGBUh83qU1q{'\n'}
VnG3rEQG/1vW8kZQ907aMnIqq7Oe/S53RbeSHznc1wLcI1qBk4omWmUIFtlh02wl{'\n'}
p0xd5ZY2ciqbIDgG2ZBv7iwlfruNRlQgh4K8yNqWAUwlz/5jS935ZGdKRZHg/6AM{'\n'}
ohBbfhzuutPUDd32t9Wd3M31Cc40EvfJDWovvNOriN+AB0fG0zIj++kfeFOm2Qdg{'\n'}
ttmow0/dbum+9xbASbtyfe/sKO2gynIKqxo4JdNoQbD/tcj4oIvqV0GR3bAD0RDo{'\n'}
OJnV1AbElGJRIdL3E//qujU4tG7SYo0fph42B1n9SWm7DZVb9jqOY8Qpun3aQR23{'\n'}
DTSh9XJT11CNcfJuZmjRDLB6YMBb3SqKBCw+vPnxN7251JuF5mRhl6cPFSPBk9r+{'\n'}
X7XaGUjDOB9TNS/1ApLTIWLR5wmj6todqkvbi2m5c0yV6rd1j+t6w0nW7WwfsYDJ{'\n'}
n/1u+beKhQMucpcmMv16hx4CkAPNjDxPJKlaaa+UcMJwKrk49ih8mVQkeU+mebnB{'\n'}
11F+Jy7Iw2B8BJ9eUx+s+mqtlprOQ7TQOpnkmxe0tw8TmseDp/L+058QA01E0iAd{'\n'}
E4ZqdfuzbweZWaM2arAS4/FVrlk5WAH1Pybj1Tqy8ejJgLXsAbdMQsitgFRQzUf0{'\n'}
bNXjnK5b{'\n'}
=MwBJ{'\n'}
-----END PGP PUBLIC KEY BLOCK-----</pre>
    </div>
  );
};

export default HelloWorld;

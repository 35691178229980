import React from 'react';
import ReconspaceUsagePdf from './reconspaceusage.pdf'; // Import the PDF file

const PdfDownloadButton = () => {
  const pdfName = ReconspaceUsagePdf.split('/').pop(); // Get the PDF file name

  return (
    <a
      href={ReconspaceUsagePdf}
      download={pdfName}
      target="_blank"
      rel="noreferrer"
    >
      <button>API-usage PDF</button>
    </a>
  );
};

export default PdfDownloadButton;

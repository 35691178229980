import React,{ useContext }  from 'react';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import Button from '@mui/material/Button';

import AuthContext from '../utils/AuthContext';
const LogoutButton = () => {
  let {logoutUser} = useContext(AuthContext)
  return (
    <div>
     <Button
        variant="outlined"
        onClick={logoutUser}
        startIcon={<ExitToAppIcon style={{ color: 'red' }} />} // Set icon color
        style={{
          color: 'red', // Set text color
          borderColor: 'red', // Set border color
        }}
      >
      </Button>
      {/* Add any additional content or styling as needed */}
    </div>
  );
};

export default LogoutButton;

import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import SatelliteAltIcon from '@mui/icons-material/SatelliteAlt';
import axios from 'axios';
import apiBaseUrl from '../../Apiconfig.js';

export default function App() {
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      try {
		const endpoint = `${apiBaseUrl}/records/`;
        const response = await axios.get(endpoint);
        const data = response.data;

        // Debugging: Log the API response

        setCount(data.Satellites || 0);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);

        // Debugging: Log the error details
        console.error('Error Details:', error.message, error.stack);

        setCount(0);
        setLoading(false);
      }
    }


    fetchData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Card sx={{ maxWidth: 300, backgroundColor: 'transparent', border: 'none',boxShadow: 'none'  }}>
      <CardMedia
        sx={{
			
          height: 50,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <SatelliteAltIcon sx={{ fontSize: 45 ,color: 'white' }} />
      </CardMedia>
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <Typography gutterBottom variant="h6" component="div" style={{ color: 'white',fontFamily: 'Montserrat, sans-serif',  }}>
          Satellites
        </Typography>
        <Typography variant="body2" color="text.secondary" style={{ color: 'white',fontFamily: 'Montserrat, sans-serif',  }}>
          {count} 
        </Typography>
      </CardContent>
    </Card>
  );

}

import React, { useState, useContext } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import axios from 'axios';
import apiBaseUrl from '../Apiconfig.js';
import AuthContext from '../utils/AuthContext';

import Typography from '@mui/material/Typography';

import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { Box } from '@mui/material';

const SearchFormId = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  let { authTokens } = useContext(AuthContext);

  const handleSearch = async () => {
    try {
      const response = await axios.get(
        `${apiBaseUrl}/orgname/?orgname=${searchTerm}`,
        {
          headers: {
            Authorization: `JWT ` + String(authTokens.access),
          },
        }
      );

      setSearchResults(response.data.results); // Assuming the API response is an array of results
      console.log(response.data.results);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  
  const [copied, setCopied] = useState(false);

  const handleCopy = (id) => {
    navigator.clipboard.writeText(id)
      .then(() => {
        console.log('ID copied to clipboard:', id);
        setCopied(true);

        // Clear the 'copied' message after a short delay (e.g., 2 seconds)
        setTimeout(() => {
          setCopied(false);
        }, 2000);
      })
      .catch((err) => {
        console.error('Error copying ID to clipboard:', err);
      });
  };

  return (
    <div>
    
    <Box
      sx={{
        padding: '30px',
        backgroundColor: '#1d1d21',
        borderRadius: 2,
        color: 'white',
        display: 'flex',
        justifyContent: 'space-between',
        verticalAlign: 'baseline' 
      }}
    >

  
  
   <TextField
        label={
          <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'white', fontFamily: 'Montserrat, sans-serif' }}>
            Search an organization by name
          </Typography>
        }
        variant="standard"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        size="small"
        sx={{
          marginBottom: '5px',
          marginRight: '20px',
          width: '100%',
          '& .MuiInput-underline:after': {
            borderBottomColor: 'green', // Custom underline color
          },
        }}
        inputProps={{
          style: { color: 'white', borderColor: 'white', borderWidth: '1px' }, // Set the color and font weight of the input text to white
        }}
      />
  
   
      <Button
      style={{
        verticalAlign: 'baseline',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      color="success"
      variant="contained"
      onClick={handleSearch}
    >
    <LocationSearchingIcon fontSize="large" />
    </Button>
    
    </Box>
      
      
      
     

       {searchResults.length > 0 && (
        <List style={{ maxHeight: 200, overflowY: 'auto' }}>
  {/* Header */}
  <ListItem
    style={{
      backgroundColor: '#0b350b',
      color: 'white',
      padding: '8px',
      marginBottom: '1px',
      borderRadius: '8px 8px 4px 4px', // Add border radius
    }}
  >
    <Typography variant="subtitle1">
      <strong>ID</strong>
    </Typography>
    <Typography variant="subtitle1" sx={{ marginLeft: '30px' }}>
      <strong>Name</strong>
    </Typography>
  </ListItem>

  {/* List items */}
  {searchResults.map((result) => (
      <>
      <ListItem
        key={result.id}
        style={{
          backgroundColor: '#1d1d21',
          color: 'white',
          fontFamily: 'Montserrat, sans-serif',
        }}
      >
        <div>
          <Typography
            variant="body1"
            onClick={() => handleCopy(result.id)}
            style={{ cursor: 'pointer', userSelect: 'none' }}
          >
            {result.id}
          </Typography>
        </div>
        <div style={{ marginLeft: '20px' }}>
          <Typography variant="body1">{result.organisationname}</Typography>
        </div>
      </ListItem>

      <Snackbar
        open={copied}
        autoHideDuration={2000}
        onClose={() => setCopied(false)}
      >
        <Alert
          onClose={() => setCopied(false)}
          severity="success"
          sx={{ width: '100%' }}
        >
          Copied!
        </Alert>
      </Snackbar>
    </>

          ))}
        </List>
      )}
    </div>
  );
};

export default SearchFormId;

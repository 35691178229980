import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import PersonIcon from '@mui/icons-material/Person';
import axios from 'axios';
import apiBaseUrl from '../../Apiconfig.js';
import IconButton from '@mui/material/IconButton';
import HelpIcon from '@mui/icons-material/Help';
import Tooltip from '@mui/material/Tooltip';

export default function App() {
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      try {
        const endpoint = `${apiBaseUrl}/records/`;
        const response = await axios.get(endpoint);
        const data = response.data;

        // Debugging: Log the API response

        setCount(data.ActiveUsers || 0);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);

        // Debugging: Log the error details
        console.error('Error Details:', error.message, error.stack);

        setCount(0);
        setLoading(false);
      }
    }

    // Debugging: Log a message when useEffect is triggered

    fetchData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    
    
    <Card sx={{  backgroundColor: '#09080A49',  color: 'white', fontFamily: 'Montserrat, sans-serif',borderRadius: '10px',}}>
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
       <CardMedia
        sx={{
			
          height: 30,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <PersonIcon sx={{ fontSize: 25 ,color: 'white',marginRight: '15px' }} />
        <Typography gutterBottom variant="h6" component="div" style={{ color: 'white',fontFamily: 'Montserrat, sans-serif',  }}>
          Active users
        </Typography>
        <Tooltip title= <div> Number of active users this month. Updated monthly.
        </div> arrow> 
      <IconButton color="primary">
        <HelpIcon />
      </IconButton>
    </Tooltip>
      </CardMedia>
        
        <Typography variant="body2" color="text.secondary" style={{ color: 'white',fontFamily: 'Montserrat, sans-serif',  }}>
         {count}
        </Typography>
      </CardContent>
    </Card>
  );

}

import React, { useEffect } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Container from '@mui/material/Container';
import { Box, SvgIcon } from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Appbar from '../components/Appbar';
import Divider from '@mui/material/Divider';
import MediaCarduser from '../components/cards/Usersnumber';



import ContactMailIcon from '@mui/icons-material/ContactMail';

import Spacer from '../components/Spacer';
import Footer from '../components/Footer';
import PdfDownloadButton from '../components/PdfDownloadButton';
import FAQContainer from '../components/FAQContainer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import ApiIcon from '@mui/icons-material/Api';

import discordIcon from './images/discord.png';
import UpdateIcon from './images/updated.png';
import ChipIcon from './images/hacker.png';

const StripePricingTable = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/pricing-table.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
  
    <div className="container">
    
    
    <Appbar/>
     <Spacer height="20px" />
      <Container sx={{
          backgroundColor: 'transparent',
          borderRadius: '6px',
          border: 'none',
          maxWidth: '98%',
        }}
        maxWidth={false}
      >
        <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          {/* Repeat this grid item for each MediaCard */}
          
          <Grid item xs={5} md={2}>
           <Card sx={{display: 'display: inline-flex',  backgroundColor: '#09080A49',  color: 'white', fontFamily: 'Montserrat, sans-serif',borderRadius: '10px',}}>
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
       <CardMedia
        sx={{
			
          height: 30,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ContactMailIcon sx={{ fontSize: 25 ,color: 'white',marginRight: '15px' }} />
        <Typography gutterBottom variant="h6" component="div" style={{ color: 'white',fontFamily: 'Montserrat, sans-serif',  }}>
          Contact
        </Typography>
      </CardMedia>
        
        <Typography variant="body2" color="text.secondary" style={{ color: 'white',fontFamily: 'Montserrat, sans-serif',  }}>
         support [at] recon.space
        </Typography>
      </CardContent>
    </Card>
          </Grid>

          <Grid item xs={5} md={2}>
           <MediaCarduser />
          </Grid>
          
        </Grid>
       
       
       
       
       
       
       
       
      </Container>
       <Spacer height="20px" />
 
      
        <Container
      sx={{
        borderRadius: '2px',
        border: 'none',
        maxWidth: '100%',
        boxShadow: '0px 0px 100px 7px rgba(0, 0, 0, 0.5)',
        padding: '10px',
        
      }}
      maxWidth={false}
    >
    
    
    <Grid container spacing={2}>
    
    <Grid item xs={4}>
          </Grid>
          
    <Grid item xs={4}>
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        color: 'white',
        fontFamily: 'Montserrat',
        marginBottom: '10px',
        backgroundColor: 'transparent', // Adjust the color to a darker shade
        borderRadius: '4px',
      }}
    >
      <Typography variant="h5" component="div" sx={{ flexGrow: 1, fontWeight: 'bold'}}>
       Recon[<span style={{ color: 'green' }}>.]</span>Space
      </Typography>
    </Box>
    </Grid>
    <Grid item xs={4}>
          </Grid>
          
          <Grid item xs={3}>
          </Grid>
    
      <Grid
    item
    xs={7}
    style={{
      position: 'relative',
      display: 'flex',
      justifyContent: 'center', // Horizontally center
      alignItems: 'center', // Vertically center
    }}
  >
        <Box
      sx={{
        display: 'flex',
        textAlign: 'justify',
        color: 'white',
        fontFamily: 'Montserrat',
        marginBottom: '10px',
        backgroundColor: 'transparent', // Adjust the color to a darker shade
        borderRadius: '4px',
      }}
    >
    
    
  
     
      <Typography variant="body1" sx={{ color: '#dfdfdf', whiteSpace: 'pre-line' }}>
      <Typography variant="body1" sx={{color: 'white', textAlign: 'center'}}>
         Recon[.]Space is an Intelligence and Collaboration Hub that provides data and analysis on space related topics.
         
		The goal is to give tools to enhance your awareness on the space stakes and reality.{'\n'}{'\n'}
      </Typography>
       

The services offered by Recon[.]Space are multiple: {'\n'}
<List>
        <ListItem><SvgIcon component={ApiIcon} sx={{ fontSize: 25, marginRight: '10px', color: 'white' }} /> An API that provide reliable up to date data, with advanced visualisation and filtering features.{'\n'}</ListItem>
        <ListItem> <img src={UpdateIcon} alt="Update Icon" style={{ width: '25px', height: '25px', marginRight: '10px' }} />  A monthly intelligence note on military capability and news on space industry available via mail.{'\n'}</ListItem>
        <ListItem><img src={discordIcon} alt="Discord Icon" style={{ width: '25px', height: '25px', marginRight: '10px'}} /> A dedicated discord to share technical informations mainly on weapons and space defense landscape.{'\n'}</ListItem>
        <ListItem><img src={ChipIcon} alt="Computer Icon" style={{ width: '25px', height: '25px', marginRight: '10px'}} /> Custom services like data enrichment, features development, and OSINT.{'\n'}</ListItem>
      </List>{'\n'}

<Typography variant="body1" sx={{color: 'white', textAlign: 'justify'}}>
         Today, Recon[.]Space can offer the best services thanks to an expansive network of points of contact, staff fully dedicated to gather information all around the world and
an active community of passionate.
      </Typography>
      </Typography>
    
    </Box>
     </Grid>
     <Grid item xs={2}>
          </Grid>
          <Grid item xs={4}>
          </Grid>
          <Grid item xs={4}>
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        color: 'white',
        fontFamily: 'Montserrat',
        backgroundColor: 'transparent', // Adjust the color to a darker shade
        
      }}
    >
      
    </Box>
    </Grid>
          <Grid item xs={4}>
          </Grid>
    
    </Grid>
   
    
      
    </Container>

     <Spacer height="20px" />
     
     
     
     
     
     
     
     
     <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '6px',
        border: 'none',
        maxWidth: '100%',
        boxShadow: '0px 0px 100px 7px rgba(0, 0, 0, 0.5)',
        paddingBottom: '1rem', // Add extra space at the bottom
        padding: '10px',
        
      }}
      maxWidth={false}
    >
      <Typography variant="h5" component="div" sx={{ flexGrow: 1, color: 'white', fontWeight: 'bold'}}>
       Recon[<span style={{ color: 'green' }}>.]</span>Space Data & API
      </Typography>
     <Spacer height="20px" />
     
        <Grid
  container
  spacing={0}
  direction="column"
  alignItems="center"
  fontFamily= 'Montserrat'
  
>
 
 <Grid item xs={7}>
  
    <Divider
  sx={{
    "&::before, &::after": {
      borderColor: "white", // Set the border color to white
    },
  }}
>
  <Typography variant="h6" component="div" sx={{  color: 'white', fontWeight: 'bold'}}>
      Our data
       
      </Typography>
</Divider>

<Typography variant="h6" component="div" sx={{  color: 'white', fontWeight: 'bold',textAlign: 'left'}}>
      Partners
      </Typography>
      <Typography variant="body1" sx={{  color: 'white',textAlign: 'justify'}}>
      Our data is sourced from trusted partners, including space agencies and big data companies. These collaborations enable us to access an important amount of high-quality and up-to-date information.
      </Typography>
      <Spacer height="15px" />

<Typography variant="h6" component="div" sx={{  color: 'white', fontWeight: 'bold',textAlign: 'left'}}>
      An active community of contributors
      </Typography>
      <Typography variant="body1" sx={{  color: 'white',textAlign: 'justify'}}>
       To encourage active participation, Recon[.]Space hosts a Discord channel where individuals can engage in discussions primarily focused on the space threat landscape. With an extensive network of points of contact, we aim to foster collaboration and knowledge sharing.
Furthermore, we provide incentives to passionate contributors who help enrich our database with valuable, verified data. By uniting the space community in this way, we can offer the most valuable data.
      </Typography>
      <Spacer height="15px" />
     
<Typography variant="h6" component="div" sx={{  color: 'white', fontWeight: 'bold',textAlign: 'left'}}>
      Data quality
      </Typography>
      <Typography variant="body1" sx={{  color: 'white',textAlign: 'justify'}}>
      Our data experts provide manual verification for our weekly updates. Before a new update, all data is verified again and again, to be certain that the data quality is maintened to the best level.
      </Typography>
      <Spacer height="15px" />
      
<Typography variant="h6" component="div" sx={{  color: 'white', fontWeight: 'bold',textAlign: 'left'}}>
      AI and Machine Learning
      </Typography>
      <Typography variant="body1" sx={{  color: 'white',textAlign: 'justify'}}>
      We leverage the power of Artificial Intelligence (AI) and Machine Learning (ML) techniques for data analysis. These advanced technologies play a pivotal role in extracting valuable insights from vast and complex datasets. To validate and assess the quality of data, over 40+ data flows and scripts are helping us daily.
      </Typography>
      <Spacer height="15px" />
      
        <Divider
  sx={{
    "&::before, &::after": {
      borderColor: "white", // Set the border color to white
    },
  }}
>
  <Typography variant="h6" component="div" sx={{  color: 'white', fontWeight: 'bold'}}>
      The API
       
      </Typography>
</Divider>

<Typography variant="h6" component="div" sx={{  color: 'white', fontWeight: 'bold',textAlign: 'left'}}>
      TLDR/Usage
      </Typography>
      <PdfDownloadButton />
        <Typography variant="body1" sx={{  color: 'white',textAlign: 'justify'}}>
      Join the discord for all details about the endpoints with actual scenarios and use cases.
      </Typography>
      <Typography variant="h6" component="div" sx={{  color: 'white', fontWeight: 'bold',textAlign: 'left'}}>
      The security of the API
      </Typography>
      <Typography variant="body1" sx={{  color: 'white',textAlign: 'justify'}}>
      The API is an interface to provides the data located in the database. In theory, with security, even if the application has been pentested, the application can present a vulnerability, if you find bug or a potential breach please report it immediately. We encourage hackers to do what they have to do but they have to inform us by contacting the support before launching any tests/attacks.
      </Typography>
      <Spacer height="15px" />
       <Typography variant="h6" component="div" sx={{  color: 'white', fontWeight: 'bold',textAlign: 'left'}}>
      The API endpoints
      </Typography>
      <Typography variant="body1" sx={{  color: 'white',textAlign: 'justify'}}>
      An Endpoint is a way to access a certain type of data in database. You can fetch multiple endpoints and combine their output to gather the information you want. It is what is done through advanced search page. Basically almost all endpoints are fetched in a single time, that how satellite, laws, technical and general data of an organization can be display in one way. You can find all endpoints available at the home page or at https://api.recon.space/documentation/. BTW, documentation/ is what we call an endpoint.
      </Typography>
      
      <Spacer height="15px" />
       <Typography variant="h6" component="div" sx={{  color: 'white', fontWeight: 'bold',textAlign: 'left'}}>
      Data update
      </Typography>
      <Typography variant="body1" sx={{  color: 'white',textAlign: 'justify'}}>
      Data is updated weekly. The API can also by under modification. Modification usually took place the activity of the API is low. A message would be displayed for scheduled shutdown.
      </Typography>
      <Spacer height="15px" />
      <FAQContainer />
    
  </Grid>



</Grid>
      <Spacer height="20px" />
    </Container>
      <Spacer height="20px" />
      
       
       <Spacer height="60px" />
      
      {/* <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        justifyContent: 'left',
        border: 'none',
        maxWidth: '100%',
        boxShadow: '0px 0px 60px 1px #12123b', 
      }}
      maxWidth={false}
    >
    
      <h2
        style={{
          color: 'white',
          fontFamily: 'Montserrat, sans-serif',
          textAlign: 'center',
          fontSize: '24px',
          marginBottom: '10px',
          padding: '10px', // Add this line
        }}
      >
         Unleash the Power of Recon[<span style={{ color: 'green' }}>.]</span>Space
      </h2>
       <Typography variant="body1" sx={{color: 'white',fontFamily: 'Montserrat, sans-serif', textAlign: 'center'}}>
         By upgrading to a user or premium account, you become an integral part of our journey and enable us to provide you with an even better experience. Let's keep the show running ! 
      </Typography>
       <Typography variant="body1" sx={{color: 'white',fontWeight: 'bold',fontFamily: 'Montserrat, sans-serif', textAlign: 'center'}}> 
         Or test the API by using the Login button and register your account.
      </Typography>
      <stripe-pricing-table
        pricing-table-id="prctbl_1O52F3J2TWV2W0xfUxaLBvkr"
        publishable-key="pk_live_51LfMjrJ2TWV2W0xfLpEsnxkA4B42gvO2QolgmzScHkNeKypNrMMYxeq1g2N5KLLhbFHBNivM25Gn6Yhhm5Z91efA00gYuNSB3E"
      ></stripe-pricing-table>
    </Container>*/}  

    
       <Spacer height="20px" />
      <Footer />
      <Spacer height="3px" />
    </div>
  );
};

export default StripePricingTable;
